import {
  Box,
  Text,
  Button,
  Link,
  MenuButton,
  Menu,
  MenuList,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  useDisclosure,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogOverlay,
  Spinner,
  Portal,
  Flex,
} from "@chakra-ui/react"
import { CheckIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons"
import { useState, useEffect, useRef } from "react"
import { useDebouncedCallback } from "use-debounce"
import { observer } from "mobx-react"
import { useMst } from "../../../../lib/hooks/use-mst"
import * as R from "ramda"
import { translate } from "../../../i18n"

interface IClientSearchState {
  searchText: string
  userIsTyping: boolean
}

export const SelectClient = observer(() => {
  const { userStore, clientSearchStore, cartStore, salesInvoiceStore, salesOrderStore, creditStore } = useMst()
  const [displayResults, setDisplayResults] = useState(true)
  const [clientSearchState, setClientSearchState] = useState<IClientSearchState>({
    searchText: "",
    userIsTyping: false,
  })
  const { isOpen: alertModalOpen, onOpen: alertModalOnOpen, onClose: alertModalOnClose } = useDisclosure()

  const cancelRef = useRef()

  const handleChangeClientString = (e): void => {
    setDisplayResults(false)
    setClientSearchState({ searchText: e.target.value, userIsTyping: true })
  }

  const handlePressNoClient = (): void => {
    if (R.pathOr(false, ["cart", "cartItems"], cartStore)) {
      //show modal if there are items in the cart
      alertModalOnOpen()
    } else {
      userStore.currentUser.clearCurrentClient()
    }
  }

  const handleChangeCurrentClient = (anipetId: number) => {
    //guard against an empty anipet id click
    anipetId ? userStore.currentUser.setCurrentClient(anipetId) : handlePressNoClient()
    // reset currentOrders/currentInvoice
    salesInvoiceStore.resetCurrentSalesInvoice()
    salesOrderStore.resetCurrentSalesOrder()
    creditStore.clearCurrentCredit()
  }
  const debounced = useDebouncedCallback((value) => {
    search(value)
  }, 500)

  useEffect(() => {
    debounced(clientSearchState.searchText)
  }, [clientSearchState.searchText, debounced])

  const search = async (term: string) => {
    //@ts-ignore
    if (clientSearchStore.isSearchable(term)) {
      await clientSearchStore?.fetchSuggestions(term)
      setDisplayResults(true)
    }
  }

  const customerName = userStore?.currentUser?.erpCustomer?.name

  return (
    <>
      <Menu isLazy>
        <MenuButton
          pr={3}
          variant={"link"}
          as={Button}
          color="background.brandBlueSecondary"
          rightIcon={<ChevronDownIcon />}
        >
          {userStore?.currentUser?.erpCustomer && (
            <Text fontSize="xl" fontWeight="normal">
              {customerName}
            </Text>
          )}
          {!userStore?.currentUser?.erpCustomer && <Text fontWeight="normal">(No Client)</Text>}
        </MenuButton>
        <Portal>
          <MenuList borderRadius="2px" p={4} w="full" zIndex={10}>
            <Box w="90vw" maxW="500px" maxH="400px">
              <Text color="gray.400" fontSize="20px" pb={2}>
                Search Client
              </Text>
              <InputGroup color="background.brandGray2">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  borderRadius="2px"
                  color="gray.500"
                  value={clientSearchState.searchText}
                  onChange={handleChangeClientString}
                  placeholder="Search by client # or name"
                ></Input>
              </InputGroup>
              {clientSearchStore.isSearching && <Spinner color="brand.orange" />}
              {displayResults && (
                <Flex p={2} maxH="200px" direction="column" overflowY="scroll" align="flex-start">
                  <Button
                    variant="link"
                    rightIcon={customerName == null && <CheckIcon color="background.brandBlueSecondary" />}
                    color={customerName == null ? "brand.blueSecondary" : "gray.500"}
                    fontWeight="normal"
                    fontSize="18px"
                    onClick={handlePressNoClient}
                  >
                    No Client
                  </Button>
                  {clientSearchStore?.results?.map((client) => {
                    const selected = userStore?.currentUser?.erpCustomer?.id == client.id
                    return (
                      <Flex
                        justify="space-between"
                        as={Link}
                        key={client.id}
                        color="gray.500"
                        w="full"
                        onClick={() => handleChangeCurrentClient(client.id)}
                        my={2}
                      >
                        <Flex direction="column">
                          <Text fontSize="18px" color={selected ? "brand.blueSecondary" : "gray.500"}>
                            {client.number} - {client.name}
                          </Text>
                          <Text color={selected ? "brand.blueSecondary" : "background.brandGray1"} fontSize="14px">
                            {client.address}, {client.city}, Canada
                          </Text>
                        </Flex>
                        {selected && <CheckIcon color="brand.blueSecondary" />}
                      </Flex>
                    )
                  })}
                </Flex>
              )}
            </Box>
          </MenuList>
        </Portal>
      </Menu>
      <AlertDialog isOpen={alertModalOpen} leastDestructiveRef={cancelRef} onClose={alertModalOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {translate("clientSelect.clearClientWarningModal.warningTitle")}
            </AlertDialogHeader>
            <AlertDialogBody>{translate("clientSelect.clearClientWarningModal.warning")}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  alertModalOnClose()
                }}
              >
                {translate("clientSelect.clearClientWarningModal.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  userStore.currentUser.clearCurrentClient()
                  alertModalOnClose()
                }}
                ml={3}
              >
                {translate("clientSelect.clearClientWarningModal.continue")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
})
