import { ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from "@chakra-ui/react"
import { HumanIcon } from "../../../../../public/images/icon/human-icon"
import NextLink from "next/link"
import { useMst } from "../../../../lib/hooks/use-mst"
import {
  ACCOUNT_NAV_ITEMS_LOGGED_IN_CUSTOMER,
  ACCOUNT_NAV_ITEMS_LOGGED_IN_REP,
  ACCOUNT_NAV_ITEMS_LOGGED_OUT,
} from "./nav-items"

export const AccountMenu = () => {
  const {
    userStore: { currentUser },
    sessionStore: { isLoggedIn },
    uiStore: { isMobileDevice },
  } = useMst()

  const ACCOUNT_NAV_ITEMS_LOGGED_IN = currentUser?.isRep
    ? ACCOUNT_NAV_ITEMS_LOGGED_IN_REP
    : ACCOUNT_NAV_ITEMS_LOGGED_IN_CUSTOMER

  return (
    <Menu key={`account-${isLoggedIn ? "logged-in" : "logged-out"}`}>
      <MenuButton
        m={0}
        as={Button}
        leftIcon={<HumanIcon color="brand.orange" boxSize={5} />}
        rightIcon={<ChevronDownIcon ml="-3" color="gray.400" />}
        variant={"ghost"}
        aria-label={"Account"}
      ></MenuButton>
      <Portal>
        <MenuList pt={isMobileDevice ? 5 : 0} zIndex={10}>
          {isLoggedIn ? (
            <>
              <Box p={2}>
                <Text color="gray.300">Welcome {currentUser.firstName}!</Text>
              </Box>
              {ACCOUNT_NAV_ITEMS_LOGGED_IN.map((navItem) =>
                !navItem.children ? (
                  <AccountMenuItem key={`mi-${navItem.key}`} navItem={navItem} color={"gray.500"} />
                ) : (
                  navItem.children.map((childItem) => {
                    return (
                      <AccountMenuItem
                        key={`ma-${childItem.key}`}
                        navItem={childItem}
                        color={childItem.label == "Logout" ? "brand.blueSecondary" : "gray.500"}
                      />
                    )
                  })
                ),
              )}
            </>
          ) : (
            ACCOUNT_NAV_ITEMS_LOGGED_OUT.map((navItem) => (
              <AccountMenuItem key={navItem.key} navItem={navItem} color={"gray.500"} />
            ))
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}

const AccountMenuItem = ({ navItem, color }) => {
  const {
    sessionStore: { logout },
    uiStore: { isMobileDevice },
  } = useMst()

  const handleClickToFunction = {
    logout,
  }

  return (
    <NextLink href={navItem.href || "/"} passHref>
      <MenuItem
        color={color}
        onClick={handleClickToFunction[navItem.handleClick]}
        h={{ base: 14, lg: 12 }}
        w={isMobileDevice ? "full" : "275px"}
        fontSize="xl"
      >
        {navItem.label}
      </MenuItem>
    </NextLink>
  )
}
