import { flow, getRoot, types } from "mobx-state-tree"
import * as R from "ramda"
import ValidateJs from "validate.js"
import { emailValidationRules, passwordValidationRules } from "../app/services/validators"
import { withEnvironment, withRootStore } from "../lib"

export const LoginFormModel = types
  .model("LoginFormModel")
  .props({
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get validationErrors() {
      return ValidateJs(
        { email: self.email, password: self.password },
        {
          email: emailValidationRules,
          password: passwordValidationRules,
        },
      )
    },
  }))
  .views((self) => ({
    get isValid() {
      return R.isNil(self.validationErrors)
    },
  }))
  .actions((self) => ({
    setEmail(email: string) {
      self.email = email.trim()
    },
    setPassword(password: string) {
      self.password = password.trim()
    },
  }))

export type TLoginForm = typeof LoginFormModel.Type
