import { Icon } from "@chakra-ui/react"

export const ScanIcon = (props) => (
  <Icon viewBox="0 0 29 24" {...props}>
    <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0338 4.83829L17.6844 2.26463C17.6081 2.18036 17.5159 2.11381 17.4142 2.06853C17.3126 2.02333 17.2033 2.00013 17.0931 2H11.1139C10.8966 2 10.6667 2.09634 10.5016 2.27303L8.17881 4.83829H2.82077C2.38558 4.83829 2 5.2084 2 5.67659V20.9115C2 21.3797 2.38558 21.7498 2.82077 21.7498H25.387C25.8222 21.7498 26.2077 21.3797 26.2077 20.9115V5.67659C26.2077 5.2084 25.8222 4.83829 25.387 4.83829H20.0338ZM9.02648 0.922446C9.56242 0.340595 10.324 0 11.1139 0H17.0939C17.4842 0.000241638 17.8702 0.0824031 18.2272 0.241235C18.5842 0.400068 18.9043 0.632079 19.1672 0.922446L20.916 2.83829H25.387C26.9384 2.83829 28.2077 4.11553 28.2077 5.67659V20.9115C28.2077 22.4726 26.9384 23.7498 25.387 23.7498H2.82077C1.26935 23.7498 0 22.4726 0 20.9115V5.67659C0 4.11553 1.26935 2.83829 2.82077 2.83829H7.2917L9.02648 0.922446Z"
        fill="#F2724E"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 17.3398V9.33838H9.05001V17.3398H8.5Z" fill="#F2724E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87524 17.3398V9.33838H10.9753V17.3398H9.87524Z"
        fill="#F2724E"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.175 17.3398V9.33838H14.2751V17.3398H13.175Z" fill="#F2724E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8003 17.3398V9.33838H12.3503V17.3398H11.8003Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5503 17.3398V9.33838H15.1003V17.3398H14.5503Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4751 17.3398V9.33838H17.5751V17.3398H16.4751Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6499 17.3398V9.33838H16.1999V17.3398H15.6499Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8501 17.3398V9.33838H18.4001V17.3398H17.8501Z"
        fill="#F2724E"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.95 17.3398V9.33838H19.5V17.3398H18.95Z" fill="#F2724E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0676 7.323H20.0886V6.323H23.0676V9.71147H22.0676V7.323Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0676 19.7283V17.3398H23.0676V20.7283H20.0886V19.7283H22.0676Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95093 17.3398H5.95093V19.7283H7.92994V20.7283H4.95093V17.3398Z"
        fill="#F2724E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02588 6.323H8.00489V7.323H6.02588V9.71147H5.02588V6.323Z"
        fill="#F2724E"
      />
    </svg>
  </Icon>
)
