import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const ServiceItemGroupModel = types
  .model("ServiceItemGroupModel")
  .props({
    id: types.number,
    slug: types.identifier,
    code: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    orderType: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  
export interface IServiceItemGroup extends Instance<typeof ServiceItemGroupModel> {}
