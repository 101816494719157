import "../styles/globals.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/cabin/400.css"
import "@fontsource/cabin-condensed/400.css"
import "@fontsource/pathway-gothic-one/400.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Provider, rootStore } from "../src/app/setup"
import { ChakraProvider } from "@chakra-ui/react"
import { appWithTranslation } from "next-i18next"
import Script from "next/script"
import { theme } from "../styles/theme"

import { Layout } from "../src/app/components/layout"
import { MaintenanceMode } from "../src/app/components/maintenance-mode"
import posthog from "posthog-js"
import '../styles/quill.scss'

function MyApp({ Component, pageProps }) {
  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") {
    return <MaintenanceMode />
  }

  if (typeof window !== "undefined") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG, {
      api_host: "https://app.posthog.com",
      session_recording: {
        maskAllInputs: true,
        inlineStylesheet: true,
      },
    })
  }

  return (
    <>
      <title>Anipet Animal Supplies</title>
      <meta
        name="description"
        content="Anipet started as a small business and has expanded to be the largest distributor in Western Canada. We are a relationship driven company with a different look on the pet industry."
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, shrink-to-fit=no"
      ></meta>
      <link rel="icon" href="/favicon.ico" />
      <Script
        id="google-tag-manager"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script id="google-data-layer" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
          `}
      </Script>

      <Provider value={rootStore}>
        <ChakraProvider theme={theme}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ChakraProvider>
      </Provider>
    </>
  )
}

export default appWithTranslation(MyApp)
