import { flow, Instance, types } from "mobx-state-tree"
import { saveCredentials } from "../app/services"
import { withEnvironment, withRootStore } from "../lib"
import { UserModel, IUser } from "../models/index"
import { EUserRole } from "../types"
import posthog from "posthog-js"
import * as Sentry from "@sentry/browser"
import { values } from "mobx"

export const UserStoreModel = types
  .model("UserStoreModel")
  .props({
    currentUser: types.maybeNull(UserModel),
    isLoading: types.optional(types.boolean, true),
    secondaryUsersMap: types.map(UserModel),

  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views(self => ({
    get secondaryUsers(): IUser[] {
      //@ts-ignore
      return values(self.secondaryUsersMap)
    }
  }))
  .actions((self) => ({
    setCurrentUser(user: IUser) {
      self.currentUser = user
      if (process.env.NEXT_PUBLIC_POSTHOG) {
        posthog.identify(
          user.id.toString(), // distinct_id, required
          { email: user.email, name: user.name, role: user.role },
        )
      }
      if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.setUser({ id: user.id.toString(), email: user.email })
      }
    },
    clearCurrentUser() {
      self.currentUser = null
      if (process.env.NEXT_PUBLIC_POSTHOG) {
        posthog.reset()
      }
      if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.setUser(null)
      }
      // call posthog. logout or reset
    },
    setIsLoading(value: boolean) {
      self.isLoading = value
    },
    
    setSecondaryAppUsers(secondaryUsers) {
      secondaryUsers.forEach((secondaryUser) => self.secondaryUsersMap.put(secondaryUser))
    },
  }))
  .actions((self) => ({
    getSecondaryAppUsers: flow(function* () {
      const response = yield self.environment.api.getAppUsers(EUserRole.secondaryUser)
      self.isLoading = true
      if (response.ok) {
        self.setSecondaryAppUsers(response.data.appUsers)
        self.isLoading = false
      }
    }),
  }))
  .actions((self) => ({
    changePassword: flow(function* (newPassword: string, newPasswordConfirmation: string) {
      try {
        const { currentUser } = self.rootStore.userStore
        const response = yield self.environment.api.changePassword(newPassword, newPasswordConfirmation)
        if (response.ok) {
          saveCredentials(currentUser.email, newPassword)
          return response
        }
      } catch {
        // error messaging handled by API monitor
      }
    }),
    createAppUser: flow(function* (email: string, firstName: string, lastName: string, role: number) {
      const response = yield self.environment.api.createAppUser(email, firstName, lastName, role)
      self.isLoading = true
      if (response.ok) {
        yield self.getSecondaryAppUsers()
        self.isLoading = false
      }
    }),
    deleteAppUser: flow(function* (id: string) {
      const response = yield self.environment.api.deleteAppUser(id)
      self.isLoading = true
      if (response.ok) {
        self.secondaryUsersMap.delete(id)
        self.isLoading = false
      }
    })
  }))

export interface IUserStore extends Instance<typeof UserStoreModel> {}