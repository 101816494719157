import { Icon } from "@chakra-ui/react"
export const LightningBolt = (props) => (
  <Icon viewBox="0 0 12 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04042 0.336994C7.37344 0.441971 7.5999 0.750809 7.5999 1.09998V5.09998L10.7999 5.09998C11.0982 5.09998 11.3717 5.26595 11.5095 5.53054C11.6472 5.79512 11.6264 6.11438 11.4553 6.35875L5.85529 14.3588C5.65505 14.6448 5.29242 14.768 4.95939 14.663C4.62637 14.558 4.39991 14.2492 4.39991 13.9V9.89999H1.1999C0.901604 9.89999 0.62808 9.73402 0.490322 9.46943C0.352564 9.20485 0.373455 8.88559 0.544519 8.64122L6.14452 0.641215C6.34476 0.355159 6.70739 0.232018 7.04042 0.336994Z"
      fill="white"
    />
  </Icon>
)
