import { flow, Instance, types } from "mobx-state-tree"
import { values, toJS } from "mobx"
import { withEnvironment, withRootStore } from "../lib"
import { DealModel, PromotionModel, IPromotion } from "../models"

export const DealStoreModel = types
  .model("DealStoreModel")
  .props({
    deal: types.maybeNull(DealModel),
    stepNumber: types.maybeNull(types.number),
    promotions: types.array(PromotionModel),
    selectedPromotion: types.maybeNull(PromotionModel),
    isLoading: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    toggleStepNumber(stepNumber: number) {
      const selectedStepNumber = self.stepNumber == stepNumber ? null : stepNumber
      self.stepNumber = selectedStepNumber
    },
    setStepNumber(stepNumber: number) {
      self.stepNumber = stepNumber
    },
    fetchDeal: flow(function* (dealId: number) {
      self.isLoading = true
      const response = yield self.environment.api.getDeal(dealId)
      if (response.ok) {
        self.deal = response.data
        self.isLoading = false
        return true
      } else {
        self.isLoading = false
        return false
      }
    }),
    getValidPromotionById: flow(function* (id) {
      self.isLoading = true
      const response = yield self.environment.api.getValidPromotionById(id)
      // console.log("getValidPromotionById res", response)
      if (response.ok) {
        self.selectedPromotion = response.data
        return true
      } else {
        self.isLoading = false
        self.selectedPromotion = undefined
        return false
      }
    }),
    reset() {
      self.stepNumber = undefined
      self.deal = undefined
    },
  }))

export interface IDealStore extends Instance<typeof DealStoreModel> {}
