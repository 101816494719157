import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const ProductCategoryModel = types
  .model("ProductCategoryModel")
  .props({
    id: types.identifierNumber,
    slug: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    childCategories: types.array(types.late(() => ProductCategoryModel)),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get parentCategory() {
      return self.rootStore.catalogueStore.productCategories.find((c: IProductCategory) =>
        c.childCategories.find((cc: IProductCategory) => cc.id == self.id || cc.id == self.slug),
      )
    },
  }))

export interface IProductCategory extends Instance<typeof ProductCategoryModel> {}
