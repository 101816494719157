import { Icon } from "@chakra-ui/react"

export const ShoppingCartIcon = (props) => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      d="M14.1799 14.1429C14.6333 14.1429 15.0199 14.3095 15.3399 14.6429C15.6599 14.9762 15.8199 15.3695 15.8199 15.8229C15.8199 16.2762 15.6599 16.6629 15.3399 16.9829C15.0199 17.3029 14.6333 17.4629 14.1799 17.4629C13.7266 17.4629 13.3333 17.3029 12.9999 16.9829C12.6666 16.6629 12.4999 16.2762 12.4999 15.8229C12.4999 15.3695 12.6666 14.9762 12.9999 14.6429C13.3333 14.3095 13.7266 14.1429 14.1799 14.1429ZM0.819946 0.822876H3.55995L4.33995 2.46288H16.6799C16.9066 2.46288 17.0999 2.54954 17.2599 2.72288C17.4199 2.88288 17.4999 3.08288 17.4999 3.32288C17.4999 3.46954 17.4599 3.59621 17.3799 3.70288L14.4199 9.10288C14.2733 9.35621 14.0666 9.56288 13.7999 9.72288C13.5466 9.88288 13.2666 9.96288 12.9599 9.96288H6.75995L6.01995 11.3229L5.97995 11.4429C5.97995 11.4962 5.99995 11.5429 6.03995 11.5829C6.07995 11.6229 6.12661 11.6429 6.17995 11.6429H15.8199V13.3229H5.81995C5.36661 13.3229 4.97995 13.1562 4.65995 12.8229C4.33995 12.4895 4.17995 12.0962 4.17995 11.6429C4.17995 11.3895 4.24661 11.1295 4.37995 10.8629L5.49995 8.78288L2.49995 2.46288H0.819946V0.822876ZM5.81995 14.1429C6.27328 14.1429 6.66661 14.3095 6.99995 14.6429C7.33328 14.9762 7.49995 15.3695 7.49995 15.8229C7.49995 16.2762 7.33328 16.6629 6.99995 16.9829C6.66661 17.3029 6.27328 17.4629 5.81995 17.4629C5.36661 17.4629 4.97995 17.3029 4.65995 16.9829C4.33995 16.6629 4.17995 16.2762 4.17995 15.8229C4.17995 15.3695 4.33995 14.9762 4.65995 14.6429C4.97995 14.3095 5.36661 14.1429 5.81995 14.1429Z"
      fill="currentColor"
    />
  </Icon>
)
