import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IProduct } from "../types"
import { formatAsPercent } from "../utils/formatter"

export const CartItemModel = types
  .model("CartItemModel")
  .props({
    id: types.maybeNull(types.number),
    product: types.maybeNull(types.frozen<IProduct>()),
    quantity: types.maybeNull(types.number),
    lineAmount: types.maybeNull(types.string),
    preDiscountLineAmount: types.maybeNull(types.string),
    discountPercent: types.maybeNull(types.string),
    promotionQualify: types.maybeNull(types.boolean),
    promotionApplied: types.maybeNull(types.boolean),
    appliedDealId: types.maybeNull(types.number),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get isDiscounted(): boolean {
      return (self.preDiscountLineAmount && self.preDiscountLineAmount != self.lineAmount) || false
    },
    get formattedDiscount(): string {
      return formatAsPercent(parseInt(self.discountPercent))
    },
    get lineAmountAsNumber(): number {
      // This is a major code smell, but this is just how the backend was designed.
      return self.lineAmount ? parseFloat(self.lineAmount.replace(/[^0-9.]/g, "")) || 0 : 0
    },
    get regularPriceAsNumber(): number {
      const numericString = self.product.price.replace(/[^0-9.]/g, "")
      return parseFloat(numericString) || 0
    },
  }))

export interface ICartItem extends Instance<typeof CartItemModel> {}
