import { Button, Text, Flex, Link, IconButton, Container } from "@chakra-ui/react"
import { AppStoreIcon } from "../../../public/images/icon/app-store-icon"
import { GooglePlayStoreIcon } from "../../../public/images/icon/google-play-store-icon"
import { InstagramFeed } from "./shared/nav/instagram-feed"
import { observer } from "mobx-react"
import { useMst } from "../../lib/hooks/use-mst"
import { FacebookIcon } from "../../../public/images/icon/facebook-icon"
import { InstagramIcon } from "../../../public/images/icon/instagram-icon"
import React, { useState, useEffect } from "react"

const defaultList = [
  { key: "my-account", href: "/my-account", label: "My Account" },
  { key: "announcements", href: "/info/announcements", label: "Announcements" },
  { key: "contact-us", href: "/info/contact-us", label: "Contact Us" },
]

export const Footer = observer(() => {
  const { catalogueStore } = useMst()
  const { footerList } = catalogueStore
  const [csrFooterList, setCsrFooterList] = useState([])

  // For some reason the footer list must be set in this way to prevent SSR/CSR hydration error
  useEffect(() => setCsrFooterList(footerList), [footerList])

  return (
    <Flex
      justifyContent={"center"}
      left={0}
      bottom={0}
      w="full"
      h="fit-content"
      minH="30%"
      backgroundColor="background.brandGray3a"
    >
      <Container as={Flex} direction="column" maxW="container.xl">
        <Flex w="full" mt={10} mb={8} flexDirection={{ base: "column", lg: "row" }}>
          <InstagramFeed />
          <Flex ml={{ base: 0, lg: 8 }} flex={1} direction="column" align="flex-start" justify="flex-start">
            <Text fontSize="2xl" mb={6} color="gray.400">
              Anipet Animal Supplies Inc.
            </Text>
            <Flex mb={6}>
              <IconButton
                as={Link}
                href="https://www.facebook.com/anipetanimalsupplies"
                isExternal
                aria-label=""
                bg="background.brandGray3a"
                icon={<FacebookIcon h={12} w={12} color="gray.400" />}
              />
              <IconButton
                ml={4}
                as={Link}
                href="https://www.instagram.com/anipetanimalsupplies/"
                isExternal
                aria-label=""
                bg="background.brandGray3a"
                icon={<InstagramIcon h={12} w={12} color="gray.400" />}
              />
            </Flex>
            <Text color="gray.400" fontSize="20px" mb={6}>
              Download the Anipet app on iPhone and Android
            </Text>

            <Flex
              mt={2}
              alignItems="flex-start"
              width={{ base: 300, lg: 150 }}
              direction={{ base: "row", lg: "column" }}
            >
              <Link href="https://apps.apple.com/ca/app/id1509491832">
                <AppStoreIcon />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.anipet"
                ml={{ base: 4, lg: 0 }}
                mt={{ base: 0, lg: 4 }}
              >
                <GooglePlayStoreIcon />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={{ base: "8px", md: 10 }}
          justify={{
            base: "flex-start",
            lg: "space-between",
            content: '"start"',
          }}
          wrap={{ base: "wrap", lg: "wrap" }}
          columnGap={10}
          rowGap={{ base: 4, md: 1 }}
        >
          {[...defaultList, ...csrFooterList].map((listItem) => {
            return (
              <Link href={listItem.href} key={listItem.key}>
                <Text color="gray.400" fontSize="lg">
                  {listItem.label}
                </Text>
              </Link>
            )
          })}
          {/* FOR TESTING SENTRY ERRORS: */}
          {process.env.NODE_ENV !== "production" && (
            <Button
              type="button"
              onClick={() => {
                console.log(process.env.NEXT_PUBLIC_SENTRY_DSN)
                console.log(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE)
                throw new Error("Sentry Frontend Error")
              }}
            >
              [QA ONLY] FError
            </Button>
          )}
        </Flex>
        <Text color="gray.400" pt={5} pb={4}>
          © Copyright {new Date().getFullYear()} Anipet Animal Supplies. All rights reserved.
        </Text>
      </Container>
    </Flex>
  )
})
