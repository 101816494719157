export const orderDateRangeStartDefault = () => {
  let defaultTimeAgo = new Date()
  //default to 1 year ago
  defaultTimeAgo.setMonth(defaultTimeAgo.getMonth() - 12)
  return defaultTimeAgo
}

export const datePickerMaxDate = () => {
  return new Date()
}

export const datePickerMinDate = () => {
  var minDate = new Date()
  //minimum date on date range picker is 10 years from present
  minDate.setFullYear(minDate.getFullYear() - 10)
  return minDate
}
