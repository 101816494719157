import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { INotification, IProduct } from "../types/types"
import * as R from "ramda"
import { translate } from "../app/i18n"

export const NotificationStoreModel = types
  .model("NotificationStoreModel")
  .props({
    productNotifications: types.array(types.frozen<INotification>()),
    productPage: types.maybeNull(types.number),
    totalProductPages: types.maybeNull(types.number),
    isProductLoaded: types.maybeNull(types.boolean),
    unreadProductNotificationsCount: types.optional(types.number, 0),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get nextProductPage() {
      return self.productPage ? self.productPage + 1 : 1
    },
    get notificationProducts() {
      return self.productNotifications.map((pn) => pn.followable) as IProduct[]
    },
  }))

  .actions((self) => ({
    convertNotificationToUseDate(item): INotification {
      return { ...item, at: item.at ? new Date(item.at * 1000) : new Date() }
    },
  }))
  .actions((self) => ({
    setProductNotifications(productNotifications) {
      self.productNotifications = productNotifications.map((m) => self.convertNotificationToUseDate(m))
    },
    concatToProductNotifications(productNotifications) {
      self.productNotifications = R.concat(
        self.productNotifications,
        productNotifications.map((m) => self.convertNotificationToUseDate(m)),
      )
    }
  }))
  .actions((self) => ({
    fetchProductNotifications: flow(function* (page = self.nextProductPage, opts = {}) {
    
      if (opts.reset) self.productPage = self.totalProductPages = undefined
      self.isProductLoaded = false
      const response = yield self.environment.api.fetchProductNotifications(page)
      if (!response?.data?.feedItems) return

      const {
        ok,
        data: { feedItems, totalPages, unreadCount },
      } = response
      
      if (ok) {
        self.unreadProductNotificationsCount = unreadCount
        self.setProductNotifications(feedItems)
        self.totalProductPages = totalPages
        self.productPage = page
        self.isProductLoaded = true
      }
    }),
    deleteNotification: flow(function* (id) {
      const response = yield self.environment.api.deleteNotification(id)
      if (response.ok) {
        self.unreadProductNotificationsCount--
        self.productNotifications = R.filter(R.compose(R.not, R.propEq("id", id)), self.productNotifications)
      }
    }),
    createProductFollow: flow(function* (productId) {
      const response = yield self.environment.api.createProductFollow(productId)
      if (response.ok) {
        self.rootStore.userStore.currentUser.addFollow(response.data.data)
      }
      return response.ok
    }),
  }))
  .actions((self) => ({
    clearProductNotifications: flow(function* () {
      self.productNotifications.forEach((n) => {
        self.deleteNotification(n.id)
      })
    }),
    deleteNotificationsByProductArray(productArray) {
      productArray.forEach((p) => {
        const notification = self.productNotifications.find((n) => n.followable.anipetItemNumber === p.productNumber)
        notification && self.deleteNotification(notification.id)
      })
    }
  }))

export interface INotificationStore extends Instance<typeof NotificationStoreModel> {}
