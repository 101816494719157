import * as R from "ramda"
import { ICredit, IItemCredit } from "../../../../models"

function fileParams(file: any, destroyed?: boolean) {
  const params: Array<object | undefined | boolean> = [
    R.isNil(file.newRecord) && { id: file.id },
    destroyed && { _destroy: true },
    !destroyed && {
      image: file.image,
    },
  ]
  return R.mergeAll(R.reject(R.isNil, params) as object[])
}

function itemCreditParams(itemCredit: IItemCredit, ogCredit?: ICredit, destroyed?: boolean) {
  let deletedFiles: any[] = []
  if (ogCredit) {
    const ogItemCredit = R.find((ic) => ic.id == itemCredit.id, ogCredit.itemCredits)
    if (!R.isNil(ogItemCredit)) {
      const isDeleted = (ogimg: any) => R.none((img) => img.id == ogimg.id, itemCredit.files)
      deletedFiles = R.filter(isDeleted, ogItemCredit.files!)
    }
  }

  const params: Array<object | undefined | boolean> = [
    R.isNil(itemCredit.newRecord) && { id: itemCredit.id },
    destroyed && { _destroy: true },
    {
      productId: itemCredit.product?.id,
      glCode: itemCredit.glCode,
      deficiencyCode: itemCredit.deficiencyCode,
      explanation: itemCredit.explanation,
      explanationCode: itemCredit.explanationCode,
      quantity: itemCredit.quantity,
      amount: itemCredit?.amount && parseFloat(itemCredit.amount),
      warehouse: itemCredit.warehouse,
      images_attributes: R.concat(
        R.map((file) => fileParams(file), itemCredit.newFiles),
        R.map((file) => fileParams(file, true), deletedFiles),
      ),
    },
  ]

  return R.mergeAll(R.reject(R.isNil, params) as object[])
}

export function creditParams(credit: ICredit, ogCredit?: ICredit) {
  const updating = !R.isNil(credit.referenceNumber)
  let deletedItemCredits: IItemCredit[] = []
  if (ogCredit) {
    const isDeleted = (ogic: IItemCredit) => R.none((ic) => ic.id == ogic.id, credit.itemCredits)
    deletedItemCredits = R.filter(isDeleted, ogCredit.itemCredits)
  }

  const params: Array<object | undefined | boolean> = [
    updating && { id: credit.id },
    {
      notes: credit.notes,
      status: credit.status,
      referenceNumber: credit.referenceNumber,
      itemCreditsAttributes: R.concat(
        R.map((ic) => itemCreditParams(ic, ogCredit), credit.itemCredits as IItemCredit[]),
        R.map((ic) => itemCreditParams(ic, ogCredit, true), deletedItemCredits),
      ),
    },
  ]

  return R.mergeAll(R.reject(R.isNil, params) as object[])
}
