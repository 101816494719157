import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { DealProductModel } from "./deal-product" // do not import from index - deal.ts is exported BEFORE deal-product.ts due to prettier so the model won't be loaded

export const DealModel = types
  .model("DealModel")
  .props({
    id: types.identifierNumber,
    description: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    step1Prompt: types.maybeNull(types.string),
    step2Prompt: types.maybeNull(types.string),
    step1Products: types.array(DealProductModel),
    step2Products: types.array(DealProductModel),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    stepPrompt(stepNumber: number): string {
      const prop = `step${stepNumber}Prompt`
      // @ts-ignore
      return self[prop]
    },
    get isValid() {
      // @TODO: find a way to determine whether the deal is valid or not without adding it to the cart
      return true
    },
  }))
  .actions((self) => ({}))

export interface IDeal extends Instance<typeof DealModel> {}
