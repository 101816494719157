import { reactotronSetup } from "./reactotron-setup"
import { RootStoreModel } from "../../stores/root-store"
import * as Monitors from "../services/api"
import { setupEnvironment } from "./environment-setup"

export function setupRootStore() {
  const environment = setupEnvironment()
  const initialState = {}
  const rootStore = RootStoreModel.create(initialState, environment)

  Monitors.addFlashMessageMonitor(environment.api, rootStore.uiStore)
  Monitors.addTokenMonitor(environment.api)
  Monitors.addApiErrorMonitor(environment.api, rootStore.uiStore)

  if (process.env.NODE_ENV == "development" && typeof window !== "undefined") {
    reactotronSetup(environment.api).then((result) => {
      if (result == "success") {
        //@ts-ignore
        console.tron && console.tron.trackMstNode && console.tron.trackMstNode(rootStore)
        require("./custom-commands").connectToRootStore(rootStore)
      }
    })
  }

  return rootStore
}
