import { Text, Box, Link, Image } from "@chakra-ui/react"

export function MaintenanceMode() {
  return (
    <Box textAlign="center" mt={150}>
      <Image src="/images/logo/anipet-logo.svg" width="180px" height="180px" alt="anipet logo" />
      <Text fontSize="4xl" mb={10}>
        2:30PM (PST) Friday, September 2nd to 8:00AM (PST) Monday, September 6th
        <br />
        Anipet System is undergoing a scheduled upgrade for both the Website & App Downtime.{" "}
      </Text>
      <Text fontSize="4xl" mb={10}>
        Contact{" "}
        <Link href="mailto:sales@anipet.com" isExternal>
          sales@anipet.com
        </Link>{" "}
        if you need assistance.{" "}
      </Text>
    </Box>
  )
}
