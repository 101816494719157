import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const BrandModel = types
  .model("BrandModel")
  .props({
    slug: types.maybeNull(types.identifier),
    id: types.number,
    name: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    imageRelayUrl: types.maybeNull(types.string),
    websiteUrl: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    brandVendorCode: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())

export interface IBrand extends Instance<typeof BrandModel> {}
