import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { EFreightType, EProductCategories, EProductLocations } from "../types"
import { IDeal } from "./"
import { CartItemModel, ICartItem } from "./cart-item" // do not import from index - cart.ts is exported BEFORE cart-item.ts due to prettier so the model won't be loaded
import * as R from "ramda"

export const CartModel = types
  .model("CartModel")
  .props({
    id: types.maybeNull(types.number),
    totalTax: types.maybeNull(types.string),
    totalDiscounts: types.maybeNull(types.string),
    subtotal: types.maybeNull(types.string),
    frozenSubtotal: types.maybeNull(types.string),
    total: types.optional(types.string, ""),
    cartItems: types.array(CartItemModel),
    orderNotes: types.maybeNull(types.string),
    externalDocumentNumber: types.maybeNull(types.string),
    appliedDeals: types.array(types.frozen<IDeal>()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get frozenMinimum(): number | undefined {
      return self.rootStore.userStore.currentUser.erpCustomer?.frozenMinimum
    },
    get surreyMinimum(): number | undefined {
      return self.rootStore.userStore.currentUser.erpCustomer?.surreyMinimum
    },
    get freightType(): EFreightType {
      return R.all((ci) => ci.product.productCategory === EProductCategories.supplies, self.cartItems)
        ? EFreightType.packaged
        : EFreightType.palletized
    },
    get nonDealCartItems(): any[] {
      return self.cartItems.filter((ci) => !ci.appliedDealId && !ci.product?.deal)
    },
    get unitTotalCount(): number {
      return self.cartItems.reduce((partialSum, cartItem) => partialSum + cartItem.quantity, 0) || 0
    },
    get dealAvailableCartItems(): any[] {
      return self.cartItems.filter((ci) => ci.product?.deal && !ci.appliedDealId)
    },
    get cartItemsByDeal(): { [key: number]: ICartItem[] } {
      return self.cartItems
        .filter((ci) => ci.appliedDealId)
        .reduce((result: { [key: string]: any }, ci) => {
          const dealId: number = ci.appliedDealId!
          const cartItems: ICartItem[] = result[dealId] || []
          cartItems.push(ci)
          result[dealId] = cartItems
          return result
        }, {})
    },
    get frozenItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.frozen)
        .reduce((acc, cur) => (acc += cur.lineAmountAsNumber), 0)
    },
    get foodItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.food)
        .reduce((acc, cur) => (acc += cur.lineAmountAsNumber), 0)
    },
    get supplyItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.supplies)
        .reduce((acc, cur) => (acc += cur.lineAmountAsNumber), 0)
    },
    get nonSurreySupplyItemSum(): number {
      return self.cartItems
        .filter(
          (ci) =>
            ci.product.productCategory === EProductCategories.supplies &&
            ci.product.itemLocation != EProductLocations.surrey,
        )
        .reduce((acc, cur) => (acc += cur.lineAmountAsNumber), 0)
    },
    get surreyItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.itemLocation === EProductLocations.surrey)
        .reduce((acc, cur) => (acc += cur.lineAmountAsNumber), 0)
    },
    get subtotalNumber(): number {
      return self.subtotal ? Number(self.subtotal.replace(/[^0-9.-]+/g, "")) : 0
    },
    get frozenSubtotalNumber(): number {
      return self.frozenSubtotal ? Number(self.frozenSubtotal.replace(/[^0-9.-]+/g, "")) : 0
    },
    cartItemsForDeal(deal: IDeal) {
      return self.cartItems.filter((ci) => ci.appliedDealId == deal.id)
    },
    getCartItemByNumber(anipetItemNumber: string) {
      return self.cartItems.find((cartItem) => cartItem?.product?.anipetItemNumber == anipetItemNumber)
    },
  }))
  .views((self) => ({
    get dealAppliedCartItems(): any[] {
      return Object.keys(self.cartItemsByDeal).map((k) => ({
        deal: self.appliedDeals.find((d) => d.id == parseInt(k)),
        cartItems: self.cartItemsByDeal[parseInt(k)],
      }))
    },
    get hasSurreyItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory === EProductCategories.supplies)
    },
    get hasFrozenItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory === EProductCategories.frozen)
    },
    get hasNonFrozenItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory !== EProductCategories.frozen)
    },
  }))
  .views((self) => ({
    get allCartItems(): any[] {
      return self.dealAvailableCartItems.concat(self.dealAppliedCartItems, self.nonDealCartItems)
    },
    get itemSum(): number {
      return self.foodItemSum + self.frozenItemSum + self.supplyItemSum
    },
    get palletizedItemSum(): number {
      return (
        self.foodItemSum +
        self.nonSurreySupplyItemSum +
        (!self.frozenMinimum || self.frozenItemSum >= self.frozenMinimum ? self.frozenItemSum : 0) +
        (!self.surreyMinimum || self.surreyItemSum >= self.surreyMinimum ? self.surreyItemSum : 0)
      )
    },
  }))
  .views((self) => ({
    get freeShippingDeficit(): number {
      const { currentUser } = self.rootStore.userStore
      switch (self.freightType) {
        case EFreightType.packaged:
          return currentUser.erpCustomer.packagedFreightMinimum - self.itemSum
        case EFreightType.palletized:
          return currentUser.erpCustomer.palletizedFreightMinimum - self.palletizedItemSum
      }
    },
    get pickupDeficit(): number {
      return self.rootStore.userStore.currentUser.erpCustomer?.localPickupMinimum - self.itemSum
    },
    get frozenItemsDeficit(): number {
      if (self.freightType == EFreightType.packaged) return 0
      return self.frozenMinimum - self.frozenItemSum
    },
    get surreyItemsDeficit(): number {
      if (self.freightType == EFreightType.packaged) return 0
      return self.surreyMinimum - self.surreyItemSum
    },
  }))
  .views((self) => ({
    get qualifiesForFreeShipping(): boolean {
      return self.freeShippingDeficit <= 0
    },
    get pickupMinimumMet(): boolean {
      return self.pickupDeficit <= 0
    },
    get surreyMinimumMet(): boolean {
      return self.surreyItemsDeficit <= 0
    },
    get frozenMinimumMet(): boolean {
      return self.frozenItemsDeficit <= 0
    },
    get regularPriceTotal(): number {
      return self.cartItems.reduce((acc, cur) => {
        return acc + cur.regularPriceAsNumber
      }, 0)
    },
  }))
  .actions((self) => ({
    setOrderNotes(notes: string) {
      self.orderNotes = notes
    },
    setExternalDocumentNumber(edn: string) {
      self.externalDocumentNumber = edn
    },
  }))

export interface ICart extends Instance<typeof CartModel> {}
