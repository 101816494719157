import { Instance } from "mobx-state-tree"
import { RootStoreModel } from "../../stores/root-store"
import { createContext } from "react"
import { setupRootStore } from "./setup-root-store"

export const rootStore = setupRootStore()
export type RootInstance = Instance<typeof RootStoreModel>
export const RootStoreContext = createContext<RootInstance | null>(null)

export const Provider = RootStoreContext.Provider
