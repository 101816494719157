export interface NavItem {
  key: string
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
  imageUrl?: string
  fontSize?: string
  color?: string
  fontWeight?: string
  handleClick?: string //provides a string lookup for the custom function to call
}

export const NAV_ITEMS: Array<NavItem> = [
  {
    key: "announcements",
    label: "Announcements",
    href: "/info/announcements",
  },
]

export const ACCOUNT_NAV_ITEMS_LOGGED_OUT: Array<NavItem> = [
  {
    key: "nav-login",
    label: "Login",
    href: "/login",
  },
]

export const ACCOUNT_NAV_ITEMS_LOGGED_IN_REP: Array<NavItem> = [
  {
    key: "nav-account",
    label: "Account",
    children: [
      {
        key: "my_account",
        label: "My Account",
        href: "/my-account",
      },
      {
        key: "order_templates",
        label: "Client Order Templates",
        href: "/my-account/order-templates",
      },
      {
        key: "saved_carts",
        label: "Rep Saved Carts",
        href: "/my-account/saved-carts",
      },
      {
        key: "logout",
        label: "Logout",
        handleClick: "logout",
      },
    ],
  },
]

export const ACCOUNT_NAV_ITEMS_LOGGED_IN_CUSTOMER: Array<NavItem> = [
  {
    key: "nav-account",
    label: "My Account",
    children: [
      {
        key: "my_account",
        label: "My Account",
        href: "/my-account",
      },
      {
        key: "order_templates",
        label: "My Order Templates",
        href: "/my-account/order-templates",
      },
      {
        key: "logout",
        label: "Logout",
        handleClick: "logout",
      },
    ],
  },
]
