import { Button, Text, ButtonProps, Link } from "@chakra-ui/react"
import NextLink from "next/link"

interface IBrandButtonProps extends ButtonProps {
  href?: string
  icon?: React.ReactElement
}

export const BrandButton = ({ icon, href, children, isDisabled, onClick, ...rest }: IBrandButtonProps) => {
  const handleClick = (event) => {
    event.preventDefault()
    !isDisabled && onClick && onClick(event)
  }

  return (
    <Button
      as={NextLink}
      href={href || "#"}
      w="157px"
      rightIcon={icon}
      isDisabled={isDisabled}
      _hover={{ textDecoration: "none", background: "gray.200" }}
      _focus={{ boxShadow: "outline", textDecoration: "none" }}
      textDecoration={"none"}
      bgColor="background.brandOrange"
      borderRadius="2px"
      textTransform="uppercase"
      color="white"
      onClick={onClick && handleClick}
      {...rest}
    >
      {children}
    </Button>
  )
}
