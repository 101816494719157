import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const SalesInvoiceModel = types
  .model("SalesInvoiceModel")
  .props({
    id: types.identifier,
    no: types.optional(types.string, ""),
    appPostedSalesInvoiceSalesInvLines: types.array(types.frozen()),
    invoiceDate: types.optional(types.string, ""),
    status: types.optional(types.string, ""),
    postingDate: types.optional(types.string, ""),
    dueDate: types.optional(types.string, ""),
    amount: types.optional(types.number, 0),
    amountIncludingVAT: types.optional(types.number, 0),
    orderNo: types.optional(types.string, ""),
    remainingAmount: types.optional(types.number, 0),
    salespersonCode: types.optional(types.string, ""),
    shipToContact: types.optional(types.string, ""),
    shipmentMethodCode: types.optional(types.string, ""),
    externalDocumentNo: types.optional(types.string, ""),
    yourReference: types.optional(types.string, ""),
    locationCode: types.optional(types.string, ""),
    sellToCustomerName: types.optional(types.string, ""),
    billToName: types.optional(types.string, ""),
    billToCity: types.optional(types.string, ""),
    billToCounty: types.optional(types.string, ""),
    billToCountry: types.optional(types.string, ""),
    billToAddress: types.optional(types.string, ""),
    billToAddress2: types.optional(types.string, ""),
    billToPostCode: types.optional(types.string, ""),
    billToCountryRegionCode: types.optional(types.string, ""),
    shipToName: types.optional(types.string, ""),
    shipToAddress: types.optional(types.string, ""),
    shipToAddress2: types.optional(types.string, ""),
    shipToPostCode: types.optional(types.string, ""),
    shipToCounty: types.optional(types.string, ""),
    shipToCity: types.optional(types.string, ""),
    shipToCountryRegionCode: types.optional(types.string, ""),
    systemId: types.string
  })
  .extend(withRootStore())
  .extend(withEnvironment())

export interface ISalesInvoiceModel extends Instance<typeof SalesInvoiceModel> {}
