import { applySnapshot, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"

export const ModalModel = types
  .model("ModalModel")
  .props({
    currentModalName: types.maybeNull(types.string),
    modalOptions: types.optional(types.frozen(), {}),
  })
  .volatile((self) => ({ defaultState: {} }))
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    isVisible(modalName?: string) {
      return modalName ? self.currentModalName == modalName : !R.isNil(self.currentModalName)
    },
  }))
  .actions((self) => ({
    show(modalName: string, options?: { [key: string]: any }) {
      self.currentModalName = modalName
      self.modalOptions = options
    },
    hide() {
      applySnapshot(self, self.defaultState)
    },
  }))

export interface IModal extends Instance<typeof ModalModel> {}
