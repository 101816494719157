import { flow, Instance, types } from "mobx-state-tree"
import { values, toJS } from "mobx"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { CartModel, ICart, ICartItem, IDeal, ICartInProgressItem, CartInProgressItemModel } from "../models"
import { dealParams } from "../app/services/api/transformers/deal"
import * as R from "ramda"
import posthog from "posthog-js"

export const CartStoreModel = types
  .model("CartStoreModel")
  .props({
    cart: types.maybeNull(CartModel),
    isSubmitting: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    // this is used to store a temporary cart for handling quantities before items are added to the cart
    cartItemsInProgressMap: types.map(CartInProgressItemModel),
    // same as above, but for the products added from the notification panel
    notificationCartItemsInProgressMap: types.map(CartInProgressItemModel),
    addingInProgress: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get cartItemsInProgress(): ICartInProgressItem[] {
      //@ts-ignore
      return values(self.cartItemsInProgressMap) as ICartInProgressItem[]
    },
    get notificationCartItemsInProgress(): ICartInProgressItem[] {
      //@ts-ignore
      return values(self.notificationCartItemsInProgressMap) as ICartInProgressItem[]
    },
    getQuantityInProgress(itemNumber, notificationCart = false): number {
      const mapToUse = notificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      return mapToUse.get(itemNumber)?.quantity || 0
    },
    get productCount() {
      return self.cart?.cartItems?.length || 0
    },
  }))
  .actions((self) => ({
    setIsSubmitting(isSubmitting: boolean) {
      self.isSubmitting = isSubmitting
    },
    setCart(cart: ICart) {
      self.cart = cart
    },
    findProductInCart(productNumber: string): ICartItem | undefined {
      return self.cart?.cartItems.find((cartItem) => cartItem.product!.anipetItemNumber == productNumber)
    },

    emptyCart: flow(function* () {
      {
        const response = yield self.environment.api.removeAllCartItems()
        if (response.ok) {
          self.cart = response.data
          return true
        } else {
          return false
        }
      }
    }),
  }))
  .actions((self) => ({
    fetchCart: flow(function* () {
      try {
        self.isLoading = true
        const response = yield self.environment.api.fetchCart()
        if (response.ok) {
          self.setCart(response.data)
        }
      } catch {
        self.isLoading = false
        // error messaging handled by API monitor
      }
      self.isLoading = false
    }),
    addMultipleToCart: flow(function* (cartItems: ICartInProgressItem[]) {
      self.addingInProgress = true
      const response = yield self.environment.api.addMultipleToCart(cartItems)
      self.addingInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          response.data.message || translate("addToCartButton.successMessage"),
          response.data.message ? "warning" : "success",
          {},
        )
        return response
      } else {
        // self.rootStore.uiStore.flashMessage.showAddToCart(
        //   translate('addToCartButton.title'),
        //   translate('addToCartButton.errorMessage'),
        //   'error',
        //   {},
        // )
        return response
      }
    }),
    addToCart: flow(function* (anipetItemNumber: string, quantity: number) {
      try {
        //can probably take out canAddToCart on productbuttons
        if (!self.rootStore.userStore.currentUser!.canAddToCart) {
          self.rootStore.uiStore.flashMessage.show(
            translate("selectClientWarning.title"),
            translate("selectClientWarning.addToCartMessage"),
            "warning",
            {},
          )
          return false
        }
        self.addingInProgress = true
        const response = yield self.environment.api.addToCart(anipetItemNumber, quantity)
        self.addingInProgress = false
        if (response.ok) {
          self.cart = response.data
          self.rootStore.uiStore.flashMessage.showAddToCart(
            translate("addToCartButton.title"),
            translate("addToCartButton.successMessage"),
            "success",
            {},
          )
          return response
        } else {
          // self.rootStore.uiStore.flashMessage.showAddToCart(
          //   translate('addToCartButton.title'),
          //   translate('addToCartButton.errorMessage'),
          //   'error',
          //   {},
          // )
          return false
        }
      } catch {
        return false
        // error messaging handled by API monitor
      }
    }),
    addToCartFromOrderTemplate: flow(function* (orderTemplateId: number) {
      self.addingInProgress = true
      const response = yield self.environment.api.addToCartFromOrderTemplate(
        orderTemplateId,
        null,
        // disabling checkbox whitelist until order template map conversion complete
        // idWhitelist,
      )
      self.addingInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          response.data.message || translate("addToCartButton.successMessage"),
          response.data.message ? "warning" : "success",
          {},
        )
        return true
      } else {
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          translate("addToCartButton.errorMessage"),
          "error",
          {},
        )
        return false
      }
    }),
    replaceCartWithOrderTemplate: flow(function* (orderTemplateId: number) {
      self.addingInProgress = true
      const response = yield self.environment.api.replaceCartWithOrderTemplate(
        orderTemplateId,
        null,
        // disabling checkbox whitelist until order template map conversion complete
        // idWhitelist,
      )
      self.addingInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          response.data.message || translate("addToCartButton.successMessage"),
          response.data.message ? "warning" : "success",
          {},
        )
        return true
      } else {
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          translate("addToCartButton.errorMessage"),
          "error",
          {},
        )
        return false
      }
    }),
    updateCartItem: flow(function* (anipetItemNumber: string, quantity: number) {
      self.addingInProgress = true
      try {
        const response = yield self.environment.api.updateCartItem(anipetItemNumber, quantity)
        self.addingInProgress = false
        if (response.ok) {
          self.cart = response.data
          self.rootStore.uiStore.flashMessage.showAddToCart(
            translate("addToCartButton.title"),
            translate("addToCartButton.successMessage"),
            "success",
            {},
          )
          return true
        } else {
          return false
        }
      } catch {
        self.addingInProgress = false
        // error messaging handled by API monitor
      }
    }),
    removeFromCart: flow(function* (cartItemId: number) {
      try {
        const response = yield self.environment.api.deleteCartItem(cartItemId)
        if (response.ok) {
          self.cart = response.data
          return true
        }
      } catch {
        // error messaging handled by API monitor
      }
      return false
    }),
    addDealToCart: flow(function* () {
      const { deal } = self.rootStore.dealStore
      const dealParamsForCart = toJS(dealParams(deal))
      const response = yield self.environment.api.addDealToCart(dealParamsForCart)
      if (response.ok) {
        self.cart = response.data
        return true
      } else {
        return false
      }
    }),
    clearCart() {
      self.cart = null
    },
    removeDealFromCart: flow(function* (deal: IDeal) {
      if (!self.cart) return false
      const cartItemsToRemove = self.cart.cartItemsForDeal(deal)
      const response = yield self.environment.api.removeCartItems(cartItemsToRemove)
      if (response.ok) {
        self.cart = response.data
        return true
      } else {
        return false
      }
    }),

    updateCartInProgress(anipetItemNumber: string, quantity: number, notificationCart = false) {
      const mapToUse = notificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      try {
        //if updated quantity is 0 remove it
        if (quantity == 0) {
          mapToUse.delete(anipetItemNumber)
        } else {
          //put into object before inserting
          mapToUse.set(anipetItemNumber, {
            productNumber: anipetItemNumber,
            quantity: quantity,
          })
        }
      } catch {
        // error messaging handled by API monitor
      }
    },

    getFilteredCartItemsByBrandVendorNames(cartItems) {
      return cartItems
        .map((cartItem) => cartItem.product.brandVendorName)
        .filter((brandVendorName, index, self) => self.indexOf(brandVendorName) === index)
        .sort()
    }
  }))
  .actions((self) => ({
    //merge cartItemsInProgress into cartItems
    mergeBothCarts() {
      //TODO
    },

    //similar to addAllToCart will be called on the
    //addCartItemsInProgressToCart - append cart items and add them to the cart, if item is already in cart, add the quantity to what's there, call clearcartInProgress
    addCartItemsInProgressToCart: flow(function* (useNotificationCart = false) {
      const arrayToUse = useNotificationCart ? self.notificationCartItemsInProgress : self.cartItemsInProgress
      const mapToUse = useNotificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      if (!self.rootStore.userStore.currentUser!.canAddToCart) {
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("selectClientWarning.title"),
          translate("selectClientWarning.addToCartMessage"),
          "warning",
          {},
        )
        return false
      }
      const multipleResponse = yield self.addMultipleToCart(arrayToUse)
      if (multipleResponse.ok && !R.isNil(multipleResponse.data)) {
        mapToUse.replace({})
        if (useNotificationCart) {
          self.rootStore.notificationStore.deleteNotificationsByProductArray(arrayToUse)
        }
      } else {
        //do nothing do not empty the cart
      }
      return multipleResponse
    }),
    removeItemsInProgress: flow(function* () {
      self.cartItemsInProgressMap.replace({})
    }),
    checkout: flow(function* (data) {
      try {
        const response = yield self.environment.api.checkout(data.orderNotes, data.externalDocumentNumber)
        if (response.ok) {
          posthog.capture("checkout", {
            username: self.rootStore.userStore.currentUser.email,
            name: self.rootStore.userStore.currentUser.name,
            role: self.rootStore.userStore.currentUser.role,
            cartTotal: self.cart.total,
            cartId: self.cart.id
          })

          //TODO: replace web with a review service on google or something
          // self.environment.appStoreReview &&
          //   self.environment.appStoreReview.trigger('cartCheckout')
          self.clearCart()
          return response
        } else {
          self.fetchCart()
        }
      } catch {
        // error messaging handled by API monitor
      }
    }),
  }))

export interface ICartStore extends Instance<typeof CartStoreModel> {}
