import * as humps from "humps"
import * as R from "ramda"

export const debounce = (limit, callback) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(callback, limit, args)
  }
}

//add depth max to be 10, likely has to do with d365 nested objects
export const camelizeResponse = (data: { [key: string]: any }) => {
  return humps.camelizeKeys(data, { depth: 10 })
}

export const decamelizeRequest = (params: { [key: string]: any }) => {
  return humps.decamelizeKeys(params, { depth: 10 })
}

export const eqValues: (...args: any[]) => boolean = R.compose(R.isEmpty, R.symmetricDifference)

export const extractNativeTags = (component) => {
  let results: any = []
  const getNativeTags = (el) => {
    if (el._nativeTag) {
      results.push(el._nativeTag)
    } else {
      results.push(el)
    }

    if (el._children) {
      results.concat(el._children.map(getNativeTags))
    }

    return results
  }

  return getNativeTags(component)
}

export const formatOsName = (osName: string) => {
  if (osName.toLowerCase() === "ios") {
    return "iOS"
  } else if (osName.toLowerCase() === "android") {
    return "Android"
  }
}
export const localStorageTest = () => {
  var test = "test"
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const priceStringToNumber = (priceString) => {
  if (!priceString) return

  return parseFloat(priceString.replace("$", "").replace(",", ""))
}

export const lineItemDiscountString = (lineItem) => {
  return (lineItem.discountPercent || lineItem.discountAmount) &&
  (lineItem.discountPercent ? `${lineItem.discountPercent.toString()}%` : `$${priceToFixed(lineItem.discountAmount)}`)
}

export const lineItemDiscountedPriceString = (lineItem) => {
  return (lineItem.discountPercent
    ? priceStringToNumber(lineItem.product.price) -
      (lineItem.discountPercent * priceStringToNumber(lineItem.product.price)) / 100
    : priceStringToNumber(lineItem.product.price) - lineItem.discountAmount
  ).toFixed(2)
}

export const priceToFixed = (priceStr) => {
  var number = parseFloat(priceStr);
  if (!isNaN(number)) {
    return number.toFixed(2);
  }
  return priceStr;
}