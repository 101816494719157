import { ApiResponse } from "apisauce"
import { destroy, flow, IMSTArray, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { CreditModel, CREDIT_DEFAULT_STATE, ICredit, IItemCredit, ItemCreditModel } from "../models"
import { ECreditStatus, ECreditType, ICreditDeficiency, IExplanation, IGlCode } from "../types"
import { translate } from "../app/i18n"

export const CreditStoreModel = types
  .model("CreditStoreModel")
  .props({
    warehouses: types.array(types.string),
    deficiencies: types.array(types.frozen<ICreditDeficiency>()),
    currentCredit: types.maybeNull(types.frozen<ICredit>()),
    glCodes: types.array(types.frozen<IGlCode>()),
    explanations: types.array(types.frozen<IExplanation>()),
    policyUrl: types.maybeNull(types.string),
    credits: types.array(types.frozen<ICredit>()),
    configuringCredit: types.maybeNull(types.boolean),
    creditForEdit: types.maybeNull(CreditModel),
    newCredit: types.optional(CreditModel, CREDIT_DEFAULT_STATE),
    itemCreditForEdit: types.maybeNull(types.reference(ItemCreditModel)),
    isLoading: types.maybeNull(types.boolean),
    creditType: types.optional(types.enumeration<ECreditType>(Object.values(ECreditType)), ECreditType.draft),
    page: types.optional(types.number, 1),
    totalPages: types.optional(types.number, 0),
    status: types.optional(types.enumeration<ECreditStatus>(Object.values(ECreditStatus)), ECreditStatus.draft),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get credit() {
      return self.creditForEdit || self.newCredit
    },
    get creditsForType() {
      let filter: (c: ICredit) => boolean
      switch (self.creditType) {
        case ECreditType.draft:
          filter = (c) => c.status == ECreditStatus.draft
          break
        case ECreditType.submitted:
          filter = (c) => c.status == ECreditStatus.submitted
          break
        case ECreditType.processed:
          filter = (c) => R.includes(c.status, [ECreditStatus.approved, ECreditStatus.rejected])
          break
      }
      return R.filter(filter, self.credits)
    },
    get approvedCredits() {
      return R.filter((c) => c.status == ECreditStatus.approved, self.credits)
    },
    deficiencyLabel(deficiencyCode: string): string {
      const deficiency = R.find((d) => d.code == deficiencyCode, self.deficiencies)
      return deficiency?.description || ""
    },
    creditIndex(credit: ICredit) {
      return R.findIndex(R.propEq("referenceNumber", credit.referenceNumber))(self.credits)
    },
    findCredit(credit: ICredit) {
      return R.find((c) => c.id == credit.id, self.credits)
    },
  }))
  .views((self) => ({
    get hasUnsavedChanges() {
      const comparisonKeys = [
        "deficiencyCode",
        "explanation",
        "explanationCode",
        "product",
        "discontinuedProduct",
        "glCode",
        "quantity",
        "warehouse",
        "files",
      ]
      const ogCredit = self.findCredit(self.credit)
      if (ogCredit) {
        // updating
        const ogItemCredits = ogCredit.itemCredits.map((ic) => R.pick(comparisonKeys, ic))
        const currentItemCredits = self.credit.itemCredits.map((ic) => R.pick(comparisonKeys, ic))
        return !R.equals(ogItemCredits, currentItemCredits)
      } else {
        // creating
        return R.any(
          (p) => p && !R.isNil(p) && !R.isEmpty(p),
          R.flatten(R.map((ic) => R.props(comparisonKeys, ic), self.credit.itemCredits)),
        )
      }
    },
    get hasMoreCreditPages(): boolean {
      return self.page < self.totalPages
    },
  }))
  .actions((self) => ({
    setCreditType(type: ECreditType) {
      self.creditType = type
    },
    setConfiguringCredit(value: boolean) {
      self.configuringCredit = value
    },
    setCreditForEdit(credit: ICredit) {
      self.creditForEdit = CreditModel.create(credit)
    },
    setItemCreditForEdit(itemCredit: IItemCredit | null) {
      self.itemCreditForEdit = itemCredit
    },
    addOrReplaceCredit(credit: ICredit) {
      const idx = self.creditIndex(credit)
      if (idx >= 0) {
        self.credits[idx] = credit
        self.credits = R.move(idx, 0, self.credits) as IMSTArray<any>
      } else {
        self.credits = R.prepend(credit, self.credits) as IMSTArray<any>
      }
    },
    removeCredit(credit: ICredit) {
      const idx = self.creditIndex(credit)
      self.credits = R.remove(idx, 1, self.credits) as IMSTArray<any>
    },
    resetCredit() {
      destroy(self.credit)
    },
    clearCredits() {
      self.credits.clear()
    },
  }))
  .actions((self) => ({
    setCurrentCredit(currentCredit) {
      self.currentCredit = currentCredit
    },
    clearCurrentCredit() {
      self.currentCredit = null
    },
    saveDraft: flow(function* (resetCredit: boolean = true) {
      if (!self.credit) return false
      let response: ApiResponse<any>
      if (self.credit.referenceNumber) {
        const ogCredit = self.findCredit(self.credit)
        response = yield self.environment.api.updateCreditRequest(self.credit, ogCredit!)
      } else {
        response = yield self.environment.api.createCreditRequest(self.credit)
      }
      if (response.ok) {
        self.addOrReplaceCredit(response.data)
        if (resetCredit) self.resetCredit()
        return true
      } else {
        return false
      }
    }),
    deleteDraft: flow(function* () {
      if (!self.credit) return false
      if (self.credit.id) {
        const response = yield self.environment.api.deleteCreditRequest(self.credit.id)
        if (response.ok) {
          self.removeCredit(self.credit)
          self.resetCredit()
          return true
        } else {
          return false
        }
      } else {
        self.resetCredit()
        return true
      }
    }),
    submitRequest: flow(function* () {
      if (!self.credit) return false
      let response
      self.credit.status = ECreditStatus.submitted
      if (self.credit.id) {
        const ogCredit = self.findCredit(self.credit)
        response = yield self.environment.api.updateCreditRequest(self.credit, ogCredit!)
      } else {
        response = yield self.environment.api.createCreditRequest(self.credit)
      }
      if (response.ok) {
        self.addOrReplaceCredit(response.data)
        self.resetCredit()
        return true
      } else {
        self.credit!.status = ECreditStatus.draft
        return false
      }
    }),
    getInitialResults: flow(function* (status: ECreditStatus, erpCustomerNumber) {
      self.isLoading = true
      self.page = 1
      self.status = status
      if (status == ECreditStatus.approved) {
        const response = yield self.environment.api.getAllCustomerPostedSalesCreditMemos(erpCustomerNumber)
        if (response.ok) {
          const mappedCredits = response.data.map(creditData => ({
            amount: creditData.amountIncludingVAT,
            postedIdentifier: creditData.no,
            processedAt: creditData.postingDate,
            systemId: creditData.systemId,
            status: "Approved", 
            referenceNumber: creditData.postingDescription
          }));
      
          self.credits.push(...mappedCredits);
        }
      } else {
        const response = yield self.environment.api.fetchCredits(self.page, self.status)
        if (response.ok) {
          self.credits = response.data.credits
          self.deficiencies = response.data.deficiencies
          self.warehouses = response.data.warehouses
          self.glCodes = response.data.glCodes
          self.explanations = response.data.explanations
          self.policyUrl = response.data.policyUrl
          self.isLoading = false
          self.totalPages = response.data.total
          return self.credits
        }
      }
      self.isLoading = false
      return []
    }),
    getNextPage: flow(function* () {
      if (!self.hasMoreCreditPages) return
      self.isLoading = true
      self.page++
      const response = yield self.environment.api.fetchCredits(self.page, self.status)
      if (response.ok && response.data.credits) {
        self.credits = self.credits.concat(response.data.credits) as IMSTArray<any>
      }
      self.isLoading = false
    }),
    downloadCreditMemoPdf: flow(function* (creditMemo) {
      const response = yield self.environment.api.downloadCreditMemoPdf(creditMemo?.systemId)

      var saveData = (function () {
        var a = document.createElement("a")
        document.body.appendChild(a)
        //@ts-ignore
        a.style = "display: none"
        return function (content, fileName) {
          const buffer = Buffer.from(content, "base64")
          const blob = new Blob([buffer], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = fileName
          a.click()
          window.URL.revokeObjectURL(url)
        }
      })()

      if (response.ok) {
        saveData(response.data, creditMemo?.postedIdentifier)
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(translate("error.genericTitle"), response?.data?.error, "danger", {})
        return false
      }
    }),
  }))

export interface ICreditStore extends Instance<typeof CreditStoreModel> {}
