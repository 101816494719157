import { useContext } from "react"
import { RootStoreContext } from "../../app/setup"

export const useMst = () => {
  const store = useContext(RootStoreContext)
  if (!store) {
    throw new Error("Store must be defined, please add a context provider")
  }
  return store
}
