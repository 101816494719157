// import DeviceInfo from 'react-native-device-info'
// import * as Keychain from 'react-native-keychain'

// const SERVER = DeviceInfo.getBundleId()

export const saveCredentials = (username: string, password: string) => {
  //TODO: UPDATE SAVE CREDENTAILS TO USE SOMETHING SECURE
  return true //Keychain.setInternetCredentials(SERVER, username, password)
}

export const loadCredentials = () => {
  //TODO: UPDATE SAVE CREDENTAILS TO USE SOMETHING SECURE
  return new Promise((resolve) => {
    resolve({ username: "test", password: "test" })
    //resolve(Keychain.getInternetCredentials(SERVER))
  })
}

export const deleteCredentials = () => {
  //TODO: UPDATE SAVE CREDENTAILS TO USE SOMETHING SECURE
  return true // Keychain.resetInternetCredentials(SERVER)
}
