import axios from "axios"
import { flow, IMSTArray, types } from "mobx-state-tree"
import * as R from "ramda"
import validate from "validate.js"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { IFile, IProduct } from "../types"

export type TItemCreditStatus = "Draft" | "Submitted" | "Pending Review" | "Approved" | "Denied"

export const ITEM_CREDIT_DEFAULT_STATE: Partial<TItemCreditData> = {}

const BASE_CONSTRAINTS = {
  quantity: {
    presence: { message: "*" },
    numericality: {
      greaterThan: 0,
      message: "*",
      onlyInteger: true,
    },
  },
  deficiencyCode: { presence: { message: "*" } },
  explanationCode: { presence: { message: "*" } },
  explanation: { presence: { message: "*" }, length: { maximum: 80 } },
  warehouse: { presence: { message: "*" } },
}

const presence = R.assoc("presence", { message: "*" }, {})
const greaterThanZero = R.assoc("numericality", { greaterThan: 0, message: "*" }, {})

/**
 * Some address types have additional constraints.
 */
const itemTypeConstraints: { [key: string]: any } = {
  product: {},
  discontinuedProduct: {
    glCode: presence,
    amount: R.mergeRight(presence, greaterThanZero),
  },
}

const glCodeConstraints = { amount: R.mergeRight(presence, greaterThanZero) }

//NOTE FOR OUR PURPOSES, PRODUCT REQUIRES, NAME, NUMBER, PRICE

export const ItemCreditModel = types
  .model("ItemCreditModel")
  .props({
    id: types.identifierNumber,
    amount: types.maybeNull(types.string),
    deficiencyCode: types.maybeNull(types.string),
    discontinuedProduct: types.maybeNull(types.boolean),
    explanation: types.maybeNull(types.string),
    explanationCode: types.maybeNull(types.string),
    glCode: types.maybeNull(types.string),
    product: types.maybeNull(types.frozen<IProduct>()),
    quantity: types.maybeNull(types.integer),
    warehouse: types.maybeNull(types.string),
    newRecord: types.maybeNull(types.boolean),
    files: types.array(types.frozen<IFile>()),
    fileIsUploading: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get quantityAsString(): string {
      return self.quantity && self.quantity > 0 ? self.quantity.toString() : ""
    },
    get itemType() {
      return self.discontinuedProduct ? "discontinuedProduct" : "product"
    },
    get newFiles() {
      return R.filter((f) => !R.isNil(f.newRecord), self.files)
    },
  }))
  .views((self) => ({
    get validationConstraints() {
      return R.mergeAll([
        itemTypeConstraints[self.itemType] || {},
        self.glCode ? glCodeConstraints : {},
        BASE_CONSTRAINTS,
      ])
    },
  }))
  .views((self) => ({
    get validationErrors() {
      //TODO: evaluate if we need to use full validate.js library just for this function
      return validate(self, self.validationConstraints, {
        fullMessages: false,
      })
    },
  }))
  .views((self) => ({
    get isValid(): boolean {
      return self.validationErrors === undefined
    },
    isOptional(field: string): boolean {
      return R.isNil(R.pathOr(null, [field, "presence"], self.validationConstraints))
    },
  }))
  .actions((self) => ({
    setItem(item: IProduct | null) {
      if (item) {
        self.discontinuedProduct = false
        self.product = item
      } else {
        self.product = null
        self.discontinuedProduct = true
      }
      self.rootStore.creditStore.setItemCreditForEdit(null)
    },
    setDeficiencyCode(code: string) {
      self.deficiencyCode = code
    },
    setExplanationCode(code: string) {
      self.explanationCode = code
    },
    setExplanation(explanation: string) {
      self.explanation = explanation
    },
    setGlCode(code: string) {
      self.glCode = code
    },
    setQuantity(quantity: number) {
      self.quantity = quantity > 0 ? quantity : null
    },
    setWarehouse(warehouse: string) {
      self.warehouse = warehouse
    },
    setAmount(amount: string) {
      self.amount = amount
    },
    //TODO: update file type as it used to be from ImagePickerResponse from "react-native-image-picker/src"
    uploadFile: flow(function* (file: any) {
      self.fileIsUploading = true

      // request a presigned S3 URL from the API
      const response = yield self.environment.api.getPresignedUrl(file.type)
      if (response.ok) {
        const data = response.data

        // prepare the form data to send to S3
        let formData = new FormData()
        formData.append("key", data.fields.key)
        formData.append("x-amz-credential", data.fields.xAmzCredential)
        formData.append("policy", data.fields.policy)
        formData.append("x-amz-algorithm", data.fields.xAmzAlgorithm)
        formData.append("x-amz-date", data.fields.xAmzDate)
        formData.append("x-amz-signature", data.fields.xAmzSignature)
        formData.append("Content-Disposition", "inline")
        formData.append("Content-Type", file.type)
        formData.append("acl", data.fields.acl)

        formData.append(
          "file",
          JSON.stringify({
            uri: file.uri,
            type: "multipart/form-data",
            name: file.fileName,
          }),
        )

        // save the file in s3
        //TODO: CLEANUP TO MOVE THE AXIOS OUT OF HERE OR OUT OF ABSTRACTION
        const awsResponse = yield axios({
          method: "POST",
          url: data.url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        if (awsResponse.status == 204) {
          // success! save the S3 image ID to the file so we can send it to the API later
          const imageId = data.fields.key.replace(/cache\//, "")
          const id = R.isEmpty(self.files) ? 1 : R.last(self.files)!.id + 1 // temp ID so we can delete the file later

          self.files.push(
            R.mergeRight(file, {
              id,
              newRecord: true,
              url: awsResponse.headers.location,
              image: { id: imageId, storage: "cache" },
            }),
          )
        } else {
          self.rootStore.uiStore.flashMessage.show(
            translate("credits.request.itemCredit.errors.fileUploadFailed.title"),
            translate("credits.request.itemCredit.errors.fileUploadFailed.message"),
            "danger",
            {},
          )
        }
        self.fileIsUploading = false
      }
    }),
    deleteFile(id: number) {
      self.files = R.filter((file: IFile) => file.id != id, self.files) as IMSTArray<any>
    },
  }))

type TItemCreditModel = typeof ItemCreditModel.Type
type TItemCreditData = typeof ItemCreditModel.CreationType
type TItemCreditSnapshot = typeof ItemCreditModel.SnapshotType

export interface IItemCredit extends TItemCreditModel {}
export interface IItemCreditData extends TItemCreditData {}
export interface IItemCreditSnapshot extends TItemCreditSnapshot {}
