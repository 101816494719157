import { BellIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { observer } from "mobx-react"
import { useEffect, useRef } from "react"
import { useMst } from "../../../../lib/hooks/use-mst"
import { Paginator } from "../../paginator"
import { ProductCard } from "../product-card"
import { BrandButton, LinkButton } from "../ui"

export const NotificationDrawer = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const { cartStore, notificationStore, uiStore } = useMst()
  const {
    productNotifications,
    unreadProductNotificationsCount,
    productPage,
    totalProductPages,
    fetchProductNotifications,
    clearProductNotifications,
    deleteNotification,
  } = notificationStore

  const { updateCartInProgress, getQuantityInProgress, addCartItemsInProgressToCart } = cartStore

  const handlePageChange = (page) => {
    fetchProductNotifications(page)
  }

  const handleAddToCart = () => {
    addCartItemsInProgressToCart(true)
  }

  useEffect(() => {
    fetchProductNotifications(1)
  }, [])

  return (
    <Box px={2}>
      <Button
        variant="link"
        fontSize="lg"
        color="gray.400"
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        _hover={{ textDecoration: "none" }}
        display={{ base: "none", md: "inline-flex" }}
        leftIcon={<BellIcon color="background.brandOrange" boxSize={5} h={5} w={5} />}>
        <Text _hover={{ textDecoration: "underline" }}>Notifications</Text>
        {unreadProductNotificationsCount > 0 && (
          <Center
            display="inline"
            bg="background.brandOrange"
            color="white"
            borderRadius="base"
            boxShadow="base"
            ml={1}
            h="20px"
            w="20px"
            fontSize="sm">
            {unreadProductNotificationsCount}
          </Center>
        )}
      </Button>
      <Box display={{ base: "block", md: "none" }} position="relative">
        <IconButton
          aria-label="Open notifications"
          ref={btnRef}
          onClick={onOpen}
          background="transparent"
          icon={
            <BellIcon
              color="background.brandOrange"
              h={uiStore.isMobileDevice ? 6 : 8}
              w={uiStore.isMobileDevice ? 6 : 8}
            />
          }>
          {unreadProductNotificationsCount > 0 && { unreadProductNotificationsCount }}
        </IconButton>
        {unreadProductNotificationsCount > 0 && (
          <Center
            position="absolute"
            top="35%"
            left="28%"
            p={0}
            bg="background.brandOrange"
            color="white"
            borderRadius="base"
            boxShadow="base"
            ml={1}
            h="10px"
            w="10px"
            fontSize="sm">
            {unreadProductNotificationsCount}
          </Center>
        )}
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex direction="column">
              <Center>
                <BellIcon h="40px" w="40px" color="background.brandOrange" />
              </Center>
            </Flex>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={{ base: 1, md: 5 }}>
            <Tabs defaultIndex={0} h="full">
              <TabList gap={5} maxW={"75rem"} margin="auto" width={"full"} borderBottom={"none"} position="relative">
                <Tab
                  color="gray.500"
                  fontSize="2xl"
                  _hover={{ color: "background.brandOrange" }}
                  _selected={{ borderBottomColor: "background.brandOrange" }}>
                  Now Available
                  {unreadProductNotificationsCount > 0 && (
                    <Box
                      position="absolute"
                      borderRadius="full"
                      h="5px"
                      w="5px"
                      bg="brand.orange"
                      top="10px"
                      left={110}></Box>
                  )}
                </Tab>
                {/* <Tab
                  color="gray.500"
                  fontSize={20}
                  _hover={{ color: 'background.brandOrange' }}
                  _selected={{ borderBottomColor: 'background.brandOrange' }}>
                  Promotions+
                </Tab> */}
              </TabList>
              <Flex w="full" h="full">
                <TabPanels w="full" h="full">
                  <TabPanel p={0} h="full">
                    <Flex w="full" py={4} justify="flex-end" borderY="solid 1px" borderX={0} borderColor="gray.200">
                      <BrandButton
                        textTransform="uppercase"
                        rightIcon={<ChevronRightIcon />}
                        onClick={handleAddToCart}
                        isDisabled={unreadProductNotificationsCount === 0}>
                        Add to Cart
                      </BrandButton>
                    </Flex>
                    {productNotifications && (
                      <Flex
                        direction="column"
                        overflowY="auto"
                        overflowX="hidden"
                        style={{ height: "calc(100% - 102px)" }}>
                        {productNotifications.map((notification) => {
                          const product = notification.followable
                          return (
                            product && (
                              <ProductCard
                                key={product.id}
                                product={product}
                                compact
                                onClickClose={() => deleteNotification(notification.id)}
                                quantity={getQuantityInProgress(product.anipetItemNumber, true)}
                                setQuantity={(updatedQuantity: number) => {
                                  updateCartInProgress(product?.anipetItemNumber, updatedQuantity, true)
                                }}
                              />
                            )
                          )
                        })}
                        {unreadProductNotificationsCount === 0 && (
                          <>
                            <Text my={6} fontSize="xl">
                              No Items to show.
                            </Text>
                            <Text fontSize="xl">
                              {
                                'Click on the "Notify Me" button for select products to be notified of when they are available to order. They will then automatically appear here for you to easily order.'
                              }
                            </Text>
                          </>
                        )}
                      </Flex>
                    )}
                  </TabPanel>
                  {/* <TabPanel>Todo: Promotion notifications</TabPanel> */}
                </TabPanels>
              </Flex>
            </Tabs>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              w="full"
              justify="space-between"
              align="center"
              borderTop="solid 1px"
              borderX={0}
              borderColor="gray.200">
              <Paginator
                page={productPage}
                totalPages={totalProductPages}
                handlePageChange={handlePageChange}
                resultsCount={unreadProductNotificationsCount}
              />
              <LinkButton onClick={clearProductNotifications}>Clear list</LinkButton>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
})
