import {
  Box,
  Divider,
  Flex,
  Image,
  HStack,
  Text,
  BoxProps,
  Center,
  Stack,
  Button,
  Spacer,
  IconButton,
  Spinner,
  Tag,
  VStack,
  Show,
  Link,
} from "@chakra-ui/react"
import { IProduct } from "../../../types"
import { ProductQuantityPicker } from "../domains/product/product-quantity-picker"
import { formatAsPrice, priceStyleSplitter } from "../../../utils/formatter"
import * as R from "ramda"
import { useMst } from "../../../lib/hooks/use-mst"
import { ShoppingCartIcon } from "../../../../public/images/icon/shopping-cart-icon"
import NextLink from "next/link"
import { DottedBox } from "../domains/product/dotted-box"
import { BellIcon, CloseIcon } from "@chakra-ui/icons"
import { useEffect, useState } from "react"
import { LightningBolt } from "../../../../public/images/icon/lightning-bolt"
import { ProductFollowButton } from "../domains/product/follow-button"
import { observer } from "mobx-react"
import { EProductDiscountType } from "../../../types"

export interface IProductCardProps extends BoxProps {
  product: IProduct
  quantity: number
  compact?: boolean
  onClickClose?: (any) => void
  setQuantity: (updatedQuantity: number) => void
  handleAdd?: (any: any) => void
}

export const ProductCard = observer(
  ({ product, quantity, setQuantity, compact, onClickClose, ...boxProps }: IProductCardProps) => {
    const {
      sessionStore: { isLoggedIn },
      cartStore,
      productSearchStore: { isLoadingCustomerBestPrices },
    } = useMst()

    const [price, setPrice] = useState(priceStyleSplitter(product.price))
    useEffect(() => {
      if (product.effectiveSalesPrice) setPrice(priceStyleSplitter(product.effectiveSalesPrice || product.price))
    }, [product])

    const currentItem = cartStore.cart?.cartItems?.find(
      (cartItem) => product.anipetItemNumber == cartItem.product.anipetItemNumber,
    )

    let promotionUrl = product.promotionSlugs[0] && "/promotions"
    if (product.promotionSlugs.length == 1) promotionUrl = promotionUrl + `/${product.promotionSlugs[0]}`

    const pickerBlock = (product: IProduct, compact: boolean) => {
      if (product.comingSoon) {
        return (
          <VStack>
            <DottedBox>Coming Soon</DottedBox>
            <ProductFollowButton product={product} />
          </VStack>
        )
      }
      if (product.blocked || product.blockedBrand) {
        return <DottedBox>Currently Unavailable</DottedBox>
      }
      return (
        <Flex direction="column" h="full" justify="center" align="flex-end">
          {!!product.effectiveSalesPrice && parseFloat(product.price) > product.effectiveSalesPrice && (
            <Text fontSize="2xl" mb={2} color="gray.400" as="s" fontFamily={"Pathway Gothic One"}>
              {formatAsPrice(product.price)}
            </Text>
          )}
          <ProductQuantityPicker
            compact={compact}
            quantity={quantity}
            setQuantity={setQuantity}
            salesUnitOfMeasure={product.salesUnitOfMeasure}
            displaySUOM={false}
          />

          {product.discountType == EProductDiscountType.clearancePrice && (
            <Tag
              alignSelf="center"
              mt={2}
              fontSize="16px"
              gap={1}
              maxW={compact ? "124px" : "160px"}
              whiteSpace="nowrap"
              borderRadius="100px"
              height="25px"
              background="#0099CC">
              <LightningBolt />
              <span style={{ textOverflow: "ellipsis", overflow: "hidden", color: "white" }}>
                {product.clearanceDiscountPercentage}% off
              </span>
            </Tag>
          )}
        </Flex>
      )
    }

    return (
      <Box
        className="product-card-box"
        backgroundColor="background.white"
        p={{ base: 2, md: 4 }}
        pb={4}
        borderBottom="1px solid"
        borderColor="gray.200"
        {...boxProps}>
        <Flex
          className="product-card-flex"
          w="full"
          justifyContent={"flex-start"}
          alignItems={{ base: "flex-start", md: "center" }}
          h="full"
          direction={{ base: "column", md: "row" }}>
          <Flex
            width={{ base: "full", md: "fit-content" }}
            justify="space-between"
            align="center"
            pr={3}
            position="relative">
            {product.sample && (
              <Center
                fontSize={compact ? "xs" : "sm"}
                fontWeight="semibold"
                color="white"
                top={0}
                h="20px"
                w={compact ? "64px" : "100px"}
                position="absolute"
                bg="background.brandOrange">
                SAMPLE
              </Center>
            )}
            {onClickClose && (
              <IconButton
                aria-label="Delete notification"
                bg="transparent"
                onClick={onClickClose}
                icon={<CloseIcon h={2} w={2} />}
                ml={{ base: "0", md: "2" }}
              />
            )}
            {!R.isEmpty(product?.imageUrls) && (
              <NextLink key={product?.id} href={`/products/${product?.slug || product?.id}`} passHref>
                <Image
                  minW={compact ? "64px" : "100px"}
                  w={compact ? "64px" : "100px"}
                  h={compact ? "64px" : "100px"}
                  src={product?.thumbImageUrls?.[0] || product?.imageUrls?.[0]}
                  objectFit="cover"
                  alt="product image"
                />
              </NextLink>
            )}
          </Flex>

          <Flex
            align="center"
            justify="center"
            gap={2}
            flexGrow={1}
            className="product-card-inner-flex"
            h="full"
            w={{ base: "full", md: "fit-content" }}
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "flex-start", md: "center" }}>
            <Flex
              direction="column"
              align="flex-start"
              justify="center"
              h="full"
              flexGrow={1}
              className="product-info-block">
              <VStack align="flex-start" spacing={0} w="full" flex={1}>
                {promotionUrl && (
                  <NextLink href={promotionUrl} passHref>
                    <Text
                      color="background.brandBlueSecondary"
                      cursor="pointer"
                      fontWeight="bold"
                      _hover={{ textDecoration: "underline" }}>
                      Promotion Available!
                    </Text>
                  </NextLink>
                )}
                <NextLink
                  key={product?.id}
                  className="product-card-name-link"
                  href={`/products/${product?.slug || product?.id}`}
                  passHref>
                  {product?.name}
                </NextLink>
              </VStack>
              <HStack>
                <Text color="gray.400" fontSize="16px" noOfLines={1}>
                  Anipet Item No.
                </Text>
                <Text color="gray.400" fontSize="18px">
                  {product?.anipetItemNumber}
                </Text>
              </HStack>
              <Text fontSize="14px" color="gray.400" noOfLines={1}>
                {product?.brandName} | {product?.vendorItemNumber} | {product?.itemLocation}
              </Text>
              {product?.specialEquipmentCode && (
                <HStack pt="2">
                  <Image
                    minW="25px"
                    minH="25px"
                    w="25px"
                    h="25px"
                    src={product?.specialEquipmentCode.imageUrl}
                    alt="special equipment code"
                  />
                  <Text mt="1" fontSize="12px" color="background.brandBlueSecondary">
                    {product?.specialEquipmentCode.description}
                  </Text>
                </HStack>
              )}
            </Flex>

            {currentItem?.quantity > 0 && (
              <Box justifyContent="center" minW="50px" flexShrink={1}>
                <Text color="gray" textAlign="center">
                  {currentItem.quantity} in <ShoppingCartIcon />
                </Text>
              </Box>
            )}

            {isLoggedIn && (
              <Flex
                justify={{ base: "space-between", md: "flex-end" }}
                w={{ base: "full", md: "fit-content" }}
                className="end-block">
                <Stack direction={{ base: "column", md: "row" }} gap={3} h="full">
                  {product?.salePrice && (
                    <Text
                      fontSize="27px"
                      textDecorationLine="line-through"
                      color="gray"
                      fontFamily={"Pathway Gothic One"}>
                      ${product?.salePrice}
                    </Text>
                  )}
                  {pickerBlock(product, compact)}
                </Stack>
                <Flex
                  direction={{ base: "row", md: "column" }}
                  align="flex-end"
                  justify="center"
                  minW={{ base: "57px", md: "83px" }}
                  className="price-block">
                  {isLoadingCustomerBestPrices ? (
                    <Spinner color="brand.orange" ml={6} />
                  ) : (
                    <Flex align="flex-start" textAlign="right" justify="flex-end">
                      <Text fontSize="4xl" color="brand.orange" fontFamily="Pathway Gothic One">
                        ${price[0]}.
                      </Text>
                      <Text fontSize="md" color="brand.orange" fontFamily="Pathway Gothic One" mt={2}>
                        {price[1]}
                      </Text>
                    </Flex>
                  )}
                  <Text textAlign="right" fontSize="sm" color="gray.500">
                    /{product?.salesUnitOfMeasure}
                  </Text>

                  {product.discountType == EProductDiscountType.customerBestPrice && (
                    <Text fontSize="sm" color="brand.orange">
                      {product.lineDiscountPercentage}% off
                    </Text>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    )
  },
)
