import { Instance, types } from "mobx-state-tree"
import { withRootStore } from "../lib/with-root-store"
import { FlashMessageModel, ModalModel } from "../models"
import { EStatusBarStyle } from "../types"

export const UIStoreModel = types
  .model("UIStoreModel")
  .props({
    waitingModalIsVisible: types.optional(types.boolean, false),
    isMobileDevice: types.optional(types.boolean, false),
    flashMessage: types.optional(FlashMessageModel, {}),
    pricesAreVisible: types.optional(types.boolean, true),
    modal: types.optional(ModalModel, {}),
    customStatusBarStyle: types.optional(
      types.enumeration<EStatusBarStyle>(Object.values(EStatusBarStyle)),
      EStatusBarStyle.default,
    ),
  })
  .extend(withRootStore())
  .views((self) => ({
    get statusBarStyle(): EStatusBarStyle {
      return self.rootStore.sessionStore.isLoggedIn
        ? self.customStatusBarStyle || EStatusBarStyle.lightContent
        : EStatusBarStyle.darkContent
    },
  }))
  .actions((self) => ({
    setStatusBarStyle(style: any | null) {
      self.customStatusBarStyle = style
    },
    setIsMobileDevice(state: boolean) {
      self.isMobileDevice = state
    },
    showWaitingModal() {
      self.waitingModalIsVisible = true
    },
    hideWaitingModal() {
      self.waitingModalIsVisible = false
    },
    togglePricesAreVisible() {
      self.pricesAreVisible = !self.pricesAreVisible
    },
  }))

export interface IUIStore extends Instance<typeof UIStoreModel> {}
