import * as R from "ramda"
import { IDeal, IDealProduct } from "../../../../models"

function dealProductParams(dealProduct: IDealProduct) {
  if (R.isNil(dealProduct.quantity) && R.isNil(dealProduct.quantityFromCart)) return null
  return {
    quantity: R.isNil(dealProduct.quantity) ? dealProduct.quantityFromCart : dealProduct.quantity,
    productNumber: dealProduct.product.anipetItemNumber,
  }
}

export function dealParams(deal: IDeal): { [key: string]: any } {
  const dealProducts = R.reject(
    R.isNil,
    R.concat(R.map(dealProductParams, deal.step1Products), R.map(dealProductParams, deal.step2Products)),
  ) as { [key: string]: any }[]

  return {
    cartItems: R.map(
      (params: { [key: string]: any }) => R.mergeRight(params, { appliedDealId: deal.id }),
      dealProducts,
    ),
  }
}
