import { flow, IMSTArray, Instance, types } from "mobx-state-tree"
import { values } from "mobx"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { BrandModel, IBrand } from "../models/brand"

export const BrandStoreModel = types
  .model("BrandStoreModel")
  .props({
    brandMap: types.map(BrandModel),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    isLoading: types.optional(types.boolean, false),
    isRefreshing: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get brands() {
      //@ts-ignore
      return values(self.brandMap) as IBrand[]
    }
  }))
  .actions((self) => ({
    getBrands: flow(function* () {
      return yield self.environment.api.getBrands(self.page)
    }),
    fetchBrand: flow(function* (id: string) {
      return yield self.environment.api.getBrand(id)
    }),
    getBrand(id: string) {
      return self.brandMap.get(id)
    },
    setBrands(brands) {
      R.forEach((obj) => self.brandMap.put(obj), brands)
    },
    setBrand(brand) {
      self.brandMap.put(brand)
    },
    setIsLoading(value: boolean) {
      self.isLoading = value
    },
    setIsRefreshing(value: boolean) {
      self.isRefreshing = value
    }
  }))
  .views((self) => ({
    get morePages() {
      return self.page < self.totalPages
    }
  }))
  .actions((self) => ({
    getInitialResults: flow(function* () {
      self.page = 1
      const response = yield self.getBrands()
      if (response.ok) {
        self.setBrands(response.data.brands)
        self.totalPages = response.data.totalPages
      }
    }),
    getNextPage: flow(function* () {
      if (self.totalPages == self.page) return
      self.isLoading = true
      self.page++
      const response = yield self.getBrands()
      if (response.ok) {
        self.setBrands(response.data.brands)
      }
      self.isLoading = false
    }),
  }))

export interface IBrandStore extends Instance<typeof BrandStoreModel> {}
