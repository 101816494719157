import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IBecomeAPartnerContent } from "../types"

export const ContentStoreModel = types
  .model("ContentStoreModel")
  .props({
    teams: types.array(types.frozen()),
    becomeAPartnerContent: types.frozen<IBecomeAPartnerContent>(),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    fetchTeams: flow(function* () {
      const response = yield self.environment.api.getTeams()
      if (response.ok) {
        self.teams = response.data
      }
    }),
    fetchBecomeAPartnerContent: flow(function* () {
      
      const response = yield self.environment.api.getBecomeAPartnerContent()
      if (response.ok) {
        self.becomeAPartnerContent = response.data
      }
    }),
  }))

export interface ITeamsStore extends Instance<typeof ContentStoreModel> {}
