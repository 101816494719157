import { flow, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { SalesInvoiceModel } from "../models/sales-invoice"

export const SalesInvoiceStoreModel = types
  .model("SalesInvoiceStoreModel")
  .props({
    salesInvoiceList: types.maybeNull(types.array(SalesInvoiceModel)),
    currentSalesInvoice: types.maybeNull(types.reference(SalesInvoiceModel)),
    downloadingPdf: types.optional(types.boolean, false),
    isSearching: types.optional(types.boolean, false),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    fetchSalesInvoices: flow(function* (params) {
      self.isSearching = true
      const response = yield self.environment.api.searchSalesInvoices(params)
      if (response.ok) {
        self.salesInvoiceList = response.data.values
        self.startDate = params.invoiceDateGteq
        self.endDate = params.invoiceDateLteq
      } else {
        self.rootStore.uiStore.flashMessage.show(translate("error.genericTitle"), response?.data?.error, "danger", {})
      }
      self.isSearching = false
    }),

    downloadInvoicePdf: flow(function* (salesInvoice) {
      self.downloadingPdf = true
      const response = yield self.environment.api.downloadInvoicePdf(salesInvoice.systemId)

      var saveData = (function () {
        var a = document.createElement("a")
        document.body.appendChild(a)
        //@ts-ignore
        a.style = "display: none"
        return function (content, fileName) {
          const buffer = Buffer.from(content, "base64")
          const blob = new Blob([buffer], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = fileName
          a.click()
          window.URL.revokeObjectURL(url)
        }
      })()

      if (response.ok) {
        saveData(response.data, salesInvoice.no)
        self.downloadingPdf = false
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(translate("error.genericTitle"), response?.data?.error, "danger", {})
        self.downloadingPdf = false
        return false
      }
    }),

    setCurrentSalesInvoice(salesInvoice) {
      self.currentSalesInvoice = salesInvoice?.id
    },
    resetCurrentSalesInvoice() {
      self.currentSalesInvoice = null
    },
  }))

export interface ISalesInvoiceStore extends Instance<typeof SalesInvoiceStoreModel> {}
