import { applySnapshot, flow, getSnapshot, IMSTArray, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { IOrderTemplate } from "../types"

export const OrderTemplateStoreModel = types
  .model("OrderTemplateStoreModel")
  .props({
    orderTemplates: types.array(types.frozen<IOrderTemplate>()),
    savedCarts: types.array(types.frozen<IOrderTemplate>()),
    orderTemplatesPage: types.optional(types.number, 1),
    totalOrderTemplatesPages: types.maybeNull(types.number),
    savedCartsPage: types.optional(types.number, 1),
    totalSavedCartsPages: types.maybeNull(types.number),
    currentOrderTemplateId: types.maybeNull(types.number),
    currentSavedCartId: types.maybeNull(types.number),
    isLoading: types.optional(types.boolean, false),
    isRefreshing: types.optional(types.boolean, false),
    isUpdatingOrderTemplate: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    getOrderTemplate(orderTemplateId: number | null): IOrderTemplate | undefined {
      return self.orderTemplates.find(
        (orderTemplate: IOrderTemplate) => orderTemplate.id === orderTemplateId,
        self.orderTemplates,
      )
    },
    getSavedCart(savedCartId: number | null): IOrderTemplate | undefined {
      return self.savedCarts.find((savedCart: IOrderTemplate) => savedCart.id === savedCartId, self.savedCarts)
    },
  }))
  .views((self) => ({
    get currentOrderTemplate(): IOrderTemplate | undefined {
      return self.getOrderTemplate(self.currentOrderTemplateId)
    },
    get currentSavedCart(): IOrderTemplate | undefined {
      return self.getSavedCart(self.currentSavedCartId)
    },
    get hasMoreOrderTemplatePages(): boolean {
      return !(self.totalOrderTemplatesPages == self.orderTemplatesPage)
    },
    get hasMoreSavedCartPages(): boolean {
      return !(self.totalSavedCartsPages == self.savedCartsPage)
    },
  }))
  .actions((self) => ({
    fetchOrderTemplates: flow(function* () {
      return yield self.environment.api.fetchOrderTemplates(self.orderTemplatesPage, "template")
    }),
    fetchSavedCarts: flow(function* () {
      return yield self.environment.api.fetchOrderTemplates(self.savedCartsPage, "saved_cart")
    }),
    createTemplate: flow(function* (name, productNumber?, quantity?) {
      name = name || `New Order Template ${new Date().toISOString()}`
      const response = yield self.environment.api.createOrderTemplate({
        name,
        productNumber,
        quantity,
        orderTemplateType: "template",
      })
      if (response.ok) {
        self.orderTemplates.unshift(response.data)
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.saved"), "success", {})
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.failed"), "error", {})
        return false
      }
    }),
    createSavedCart: flow(function* (name, productNumber?, quantity?) {
      name = name || `New Rep Saved Cart ${new Date().toISOString()}`
      const response = yield self.environment.api.createOrderTemplate({
        name,
        productNumber,
        quantity,
        orderTemplateType: "saved_cart",
      })
      if (response.ok) {
        self.savedCarts.unshift(response.data)
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.savedCart"), "success", {})
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.savedCartFailed"), "error", {})
        return false
      }
    }),
    updateOrderTemplate(newTemplateData: IOrderTemplate) {
      // Return the single object that is updated
      // and then merge the update with the array that
      // already exist based on whether or not that
      // item is a rep or non-rep template
      // The back end should return a single object
      self.isUpdatingOrderTemplate = true
      const arr = newTemplateData.orderTemplateType == "saved_cart" ? self.savedCarts : self.orderTemplates

      const idx = arr.findIndex((ot) => ot.id === newTemplateData.id)
      arr[idx] = newTemplateData
      // self.currentSavedCartId = newTemplateData.id
      self.isUpdatingOrderTemplate = false
    },
    deleteOrderTemplate: flow(function* (orderTemplate: IOrderTemplate) {
      const response = yield self.environment.api.deleteOrderTemplate(orderTemplate?.id)
      if (response.ok) {
        if (orderTemplate.orderTemplateType == "saved_cart") {
          self.savedCarts.remove(orderTemplate)
        } else {
          self.orderTemplates.remove(orderTemplate)
        }
      }
    }),
  }))
  .actions((self) => ({
    createOrderTemplateFromCart: flow(function* (name) {
      yield self.createTemplate(name)
    }),

    createSavedCartFromCart: flow(function* (name) {
      yield self.createSavedCart(name)
    }),
    updateName: flow(function* (orderTemplateId: number, name: string) {
      const response = yield self.environment.api.updateOrderTemplateName(orderTemplateId, name)

      if (response.ok) {
        self.updateOrderTemplate(response.data)
        return true
      } else {
        return false
      }
    }),
    addOrderTemplateItem: flow(function* (orderTemplateId: number, productNumber, quantity: number) {
      self.isUpdatingOrderTemplate = true
      const response = yield self.environment.api.createOrderTemplateItem(orderTemplateId, productNumber, quantity)
      if (response.ok) {
        self.rootStore.uiStore.flashMessage.show(null, translate("generic.saved"), "success", {})
        self.updateOrderTemplate(response.data)
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("generic.failed"), "error", {})
      }
      self.isUpdatingOrderTemplate = false
    }),
    updateOrderTemplateItem: flow(function* (orderTemplateItemId: number, quantity: number, orderTemplate = null) {
      const arr = orderTemplate.orderTemplateType == "saved_cart" ? self.savedCarts : self.orderTemplates
      // if order template is provided, then optimistic update

      self.isUpdatingOrderTemplate = true
      const response = yield self.environment.api.updateOrderTemplateItem(orderTemplateItemId, quantity)
      if (response.ok) {
        self.updateOrderTemplate(response.data)
      }
      self.isUpdatingOrderTemplate = false
    }),
    removeOrderTemplateItem: flow(function* (orderTemplateItemId: number) {
      const response = yield self.environment.api.removeOrderTemplateItem(orderTemplateItemId)
      if (response.ok) {
        self.updateOrderTemplate(response.data)
      }
    }),
    setCurrentOrderTemplateId(orderTemplateId: number | null) {
      self.currentOrderTemplateId = orderTemplateId
    },
    setCurrentSavedCartId(savedCartId: number | null) {
      self.currentSavedCartId = savedCartId
    },
    setIsLoading(value: boolean) {
      self.isLoading = value
    },
    setIsRefreshing(value: boolean) {
      self.isRefreshing = value
    },
    getInitialOrderTemplatesPage: flow(function* () {
      self.orderTemplatesPage = 1
      self.isLoading = true
      const response = yield self.fetchOrderTemplates()
      if (response.ok) {
        self.orderTemplates = response.data.orderTemplates
        self.totalOrderTemplatesPages = response.data.totalPages
        self.isLoading = false
        return self.orderTemplates
      }
      self.isLoading = false
      return []
    }),
    getNextOrderTemplatesPage: flow(function* () {
      if (!self.hasMoreOrderTemplatePages) return
      self.isLoading = true
      self.orderTemplatesPage++
      const response = yield self.fetchOrderTemplates()
      if (response.ok) {
        self.orderTemplates = self.orderTemplates.concat(response.data.orderTemplates) as IMSTArray<any>
      }
      self.isLoading = false
    }),
    getInitialSavedCartsPage: flow(function* () {
      self.savedCartsPage = 1
      self.isLoading = true
      const response = yield self.fetchSavedCarts()
      if (response.ok) {
        self.savedCarts = response.data.orderTemplates
        self.totalSavedCartsPages = response.data.totalPages
        self.isLoading = false
        return self.savedCarts
      }
      self.isLoading = false
      return []
    }),
    getNextSavedCartsPage: flow(function* () {
      if (!self.hasMoreSavedCartPages) return
      self.isLoading = true
      self.savedCartsPage++
      const response = yield self.fetchSavedCarts()
      if (response.ok) {
        self.savedCarts = self.savedCarts.concat(response.data.orderTemplates) as IMSTArray<any>
      }
      self.isLoading = false
    }),
  }))

export interface IOrderTemplateStore extends Instance<typeof OrderTemplateStoreModel> {}
