import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const SalesOrderLineModel = types
  .model("SalesOrderLineModel")
  .props({
    number: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    quantity: types.maybeNull(types.number),
    lineAmount: types.maybeNull(types.string),
    lineDiscountAmount: types.maybeNull(types.string),
    lineDiscountPercent: types.maybeNull(types.number),
    upcCode: types.maybeNull(types.string),
    unitOfMeasure: types.maybeNull(types.string),
    unitPrice: types.maybeNull(types.string),
    tax: types.maybeNull(types.string),
    totalIncludeTax: types.maybeNull(types.string),
    subtotal: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    setNumber(number) {
      self.number = number
    },
  }))

export interface ISalesOrderLineModel extends Instance<typeof SalesOrderLineModel> {}
