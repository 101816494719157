import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const SpecialEquipmentCodeModel = types
  .model("SpecialEquipmentCodeModel")
  .props({
    id: types.number,
    slug: types.maybeNull(types.identifier),
    showInNav: types.maybeNull(types.boolean),
    code: types.string,
    description: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    key: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())

export interface ISpecialEquipmentCode extends Instance<typeof SpecialEquipmentCodeModel> {}
