import { Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { IFilterOption } from "../types"

export const FilterModel = types
  .model("FilterModel")
  .props({
    name: types.maybeNull(types.string),
    filterKey: types.maybeNull(types.string),
    options: types.array(types.frozen()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get sortedOptions() {
      const allOptions: IFilterOption[] = self.options
        .map((option: any) => {
          if (option.options) {
            return option.options.map((subOption: IFilterOption) => {
              return R.mergeRight({ filterKey: option.filterKey }, subOption)
            })
          } else {
            return option
          }
        })
        .flat()

      return R.sortBy(R.compose(R.toLower, R.prop("displayName")), allOptions)
    },
  }))

export interface IFilter extends Instance<typeof FilterModel> {}
