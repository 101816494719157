import { Icon } from "@chakra-ui/react"

export const HumanIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M6.99998 0C1.39998 0 4.09998 7.3 4.09998 7.3C4.69998 8.3 5.49998 8.1 5.49998 8.8C5.49998 9.4 4.79998 9.6 4.09998 9.7C2.99998 9.7 1.99998 9.5 0.999976 11.3C0.399976 12.4 0.0999756 16 0.0999756 16H13.8C13.8 16 13.5 12.4 13 11.3C12 9.4 11 9.7 9.89997 9.6C9.19998 9.5 8.49998 9.3 8.49998 8.7C8.49998 8.1 9.29997 8.3 9.89997 7.2C9.89997 7.3 12.6 0 6.99998 0Z"
      fill="currentColor"
    />
  </Icon>
)
