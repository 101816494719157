import {
  Flex,
  Text,
  IconButton,
  Button,
  useDisclosure,
  Image,
  Spacer,
  Link,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  AccordionItemProps,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Heading,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronLeftIcon, SearchIcon } from "@chakra-ui/icons"
import { ShoppingCartIcon } from "../../../../../public/images/icon/shopping-cart-icon"
import { SearchBar } from "../search/search-bar"
import NextLink from "next/link"
import { SelectClient } from "./select-client"

import { NavItem } from "./nav-items"
import { useMst } from "../../../../lib/hooks/use-mst"
import { useRouter } from "next/router"
import { BrandButton } from "../ui"
import { observer } from "mobx-react"
import { AccountMenu } from "./account-menu"
import NoSSR from "react-no-ssr"
import { NotificationDrawer } from "./notification-drawer"

interface IMobileHeaderProps {
  navItems: NavItem[]
  cartProductCount: number
  onClickSearchIcon: () => void
}

export const MobileHeader = observer(({ navItems, cartProductCount, onClickSearchIcon }: IMobileHeaderProps) => {
  const {
    sessionStore: { isLoggedIn },
  } = useMst()
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <NoSSR>
        <Flex id="MobileHeaderHeader" className="sticky" bg="white">
          <Flex p={4} justify="flex-start" align="center">
            <IconButton
              onClick={onToggle}
              icon={<HamburgerIcon w="30px" h="30px" />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
            <LogoLink />
            <Spacer />
            <NotificationDrawer />
            <IconButton
              onClick={onClickSearchIcon}
              aria-label=""
              bg="white"
              icon={<SearchIcon color="brand.orange" boxSize={5} />}
            />
            {isLoggedIn ? (
              <>
                <NextLink href={"/shopping-cart"} passHref>
                  <Button
                    leftIcon={<ShoppingCartIcon color={"brand.orange"} display="inline" boxSize={5} />}
                    variant={"ghost"}
                    fontSize="2xl"
                    fontFamily={"Pathway Gothic One"}
                    aria-label={"Cart"}
                  >
                    {cartProductCount > 0 ? cartProductCount : 0}
                  </Button>
                </NextLink>
                <AccountMenu />
              </>
            ) : (
              <BrandButton href="/login">Login</BrandButton>
            )}
          </Flex>
        </Flex>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader p={4}>
              <Flex align="center">
                <DrawerCloseButton position="static" boxSize={10} color="gray.400" mr={4} />
                <NextLink href={"/"} passHref>
                  <Image mr={4} src="/images/logo/anipet-logo.svg" alt="logo" display="inline" height="80px" />
                </NextLink>
                <Text h="full" fontSize="xl" textTransform="uppercase" color="gray.400">
                  Menu
                </Text>
              </Flex>
            </DrawerHeader>
            <DrawerBody>
              <Flex
                gap={4}
                id="MobileNav"
                direction="column"
                bg="white"
                display={{ base: "flex", md: "none" }}
                borderBottom="1px solid #333"
              >
                <Accordion allowToggle as={Flex} gap={4} flexDirection="column">
                  {navItems.map(
                    (navItem) =>
                      navItem && (
                        <MobileNavItem key={navItem.key} navItem={navItem} onNavigate={onClose}>
                          {navItem.label}
                        </MobileNavItem>
                      ),
                  )}
                </Accordion>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </NoSSR>
    </>
  )
})

const LogoLink = () => {
  return (
    <NextLink href={"/"} passHref>
      <Image mr={4} src="/images/logo/anipet-logo.svg" alt="logo" display="inline" height="80px" width="100px" />
    </NextLink>
  )
}

interface IMobileNavItemProps extends AccordionItemProps {
  navItem: NavItem
  onNavigate: () => void
}

export const MobileNavItem = ({ navItem, children, onNavigate, ...rest }: IMobileNavItemProps) => {
  const router = useRouter()
  const { catalogueStore } = useMst()
  const { href } = navItem

  const handleClick = (event) => {
    onNavigate()
    href && router.push(href)
  }

  return (
    <AccordionItem
      w="full"
      color="black"
      _focus={{ boxShadow: "o`utline", textDecoration: "none" }}
      textDecoration={"none"}
      bgColor="white"
      boxShadow="4px 4px 4px rgba(222, 222, 222)"
      fontSize="2xl"
      justifyContent="space-between"
      {...rest}
    >
      <AccordionButton
        pr={0}
        py={0}
        borderRadius="base"
        border="1px solid"
        borderColor="gray.200"
        h={16}
        overflow="hidden"
        onClick={!navItem.children ? handleClick : undefined}
      >
        <Flex justify="space-between" w="full" align="center" h="full">
          <Flex>
            <Text
              fontSize="2xl"
              color={navItem.key === catalogueStore.promotionsPlusNavItem.key ? "brand.orange" : "black"}
            >
              {children}
            </Text>
            {navItem.children && <AccordionIcon mt={2} ml={2} />}
          </Flex>
          {navItem.imageUrl && <Image src={navItem.imageUrl} alt={children?.toString()} w="30%" />}
        </Flex>
      </AccordionButton>
      <AccordionPanel w="full" p={0}>
        <Flex direction="column" pl={navItem.children ? 8 : 0} pb={navItem.children ? 2 : 0} bg="white" w="full">
          {navItem.children &&
            navItem.children.map((child) => (
              <NextLink key={child.key} href={child.href} passHref>
                <Link py={2} color="black" width="full" onClick={(e) => onNavigate()} fontSize="xl">
                  {child.label}
                </Link>
              </NextLink>
            ))}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}
