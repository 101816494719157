import * as R from "ramda"
import { IUIStore } from "../../../stores"
import { API_ERROR_TYPES } from "../../../utils"
import { Api } from "./api"

/**
  Watches for "flash" messages from the server and reports them.
 */
export const addFlashMessageMonitor = (api: Api, uiStore: IUIStore) => {
  api.addMonitor((response) => {
    const messageConfig: { [key: string]: any } = R.path(["data", "meta", "message"], response)
    if (R.isNil(messageConfig) || R.isEmpty(messageConfig)) return
    const { title, message, type } = messageConfig
    uiStore.flashMessage.show(title, message, type, {})
  })
}

/**
  Adds the auth tokens from the server response to the next request
 */
export const addTokenMonitor = (api: Api) => {
  api.addMonitor((response) => {
    if (response.ok) {
      const accessToken = R.path(["headers", "access-token"], response)
      if (accessToken) {
        api.setAuthHeaders(response.headers)
      }
    }
  })
}

export const addApiErrorMonitor = (api: Api, uiStore: IUIStore) => {
  api.addMonitor((response) => {
    if (response.problem) {
      const err = API_ERROR_TYPES[response.problem]
      if (err) {
        uiStore.flashMessage.show(response.problem, err.message, err.type, {})
      }
    }
  })
}
