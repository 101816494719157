import { ICartItem } from "../../../../models"

function cartItemParams(cartItem: ICartItem): { [key: string]: any } {
  return {
    quantity: cartItem.quantity,
    productNumber: cartItem.product!.anipetItemNumber,
  }
}

export function cartItemsParams(cartItems: ICartItem[]): { [key: string]: any } {
  return {
    cartItems: cartItems.map(cartItemParams),
  }
}
