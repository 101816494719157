import { Box } from "@chakra-ui/react"
import { useMst } from "../../../lib/hooks/use-mst"

export const WarningBanner = ({}) => {
  const { catalogueStore } = useMst()
  const warningBanner = catalogueStore?.systemSetting

  return (
    <Box
      bg="background.brandOrange"
      p={2}
      display={warningBanner?.published ? "flex" : "none"}
      alignItems="center"
      justifyContent="center"
    >
      {catalogueStore?.systemSetting?.description}
    </Box>
  )
}
