import { Flex, Text, Input, IconButton } from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { useToast } from "@chakra-ui/react"
export interface IProductQuantityPickerProps {
  quantity: number
  compact?: boolean
  setQuantity: (number) => void
  salesUnitOfMeasure: string
  displaySUOM: boolean
}

export const ProductQuantityPicker = ({
  quantity,
  setQuantity,
  salesUnitOfMeasure,
  displaySUOM,
  compact,
}: IProductQuantityPickerProps) => {
  const toast = useToast()

  const checkForString = (e) => {
    if (e != parseInt(e)) {
      toast({
        position: "bottom-left",
        title: "Error",
        description: "Please enter a number for the quantity.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    } else {
      setQuantity(parseInt(e))
    }
  }

  return (
    <Flex w={compact ? "124px" : "160px"} h={compact ? "40px" : "50px"} direction="row">
      <Flex border="1px solid #D6D6D6" borderRadius="base" p={compact ? 0 : 1} alignItems="center" minW="full">
        <IconButton
          backgroundColor="background.brandOrange"
          _hover={{ bgColor: "background.brandOrange" }}
          color="white"
          onClick={() => {
            quantity > 0 ? setQuantity(quantity - 1) : quantity
          }}
          aria-label="Minus 1 More"
          icon={
            <MinusIcon bgColor="background.brandOrange" color="white" w={7} h={7} p={{ base: 1, md: 2 }}></MinusIcon>
          }
        />
        <Input
          p={compact ? 0 : 1}
          textAlign="center"
          border={0}
          fontSize="20px"
          placeholder="0"
          onChange={(e) => checkForString(e.target.value)}
          value={quantity}
          type="number"
        />
        <IconButton
          backgroundColor="background.brandOrange"
          _hover={{ bgColor: "background.brandOrange" }}
          color="white"
          onClick={() => {
            setQuantity(quantity + 1)
          }}
          aria-label="Add 1 More"
          icon={<AddIcon backgroundColor="background.brandOrange" color="white" w={7} h={7} p={2}></AddIcon>}
        />
      </Flex>

      {/* Allows the same component to be used on both product, category list, shopping cart */}
      {displaySUOM && (
        <Text alignSelf={"center"} ml={2}>
          {salesUnitOfMeasure}
        </Text>
      )}
    </Flex>
  )
}
