import { Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { IClient } from "../types/types"

export const ClientStoreModel = types
  .model("ClientStoreModel")
  .props({})
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    getClient(client?: IClient | null): IClient | null {
      return client || self.rootStore.userStore.currentUser.erpCustomer
    },
  }))
  .views((self) => ({
    getDisplayName(client?: IClient | null): string {
      client = self.getClient(client)
      return client ? `${client.number} - ${client.name}` : translate("clientSelect.noClientOption")
    },
    getContactInformation(client?: IClient | null): string | null {
      client = self.getClient(client)
      return client && `${client.address}, ${client.city} `
    },
  }))
  .actions((self) => ({}))

export interface IClientStore extends Instance<typeof ClientStoreModel> {}
