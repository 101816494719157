// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import { BrowserTracing } from "@sentry/tracing"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
// const AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN || process.env.AUTH_TOKEN

Sentry.init({
  // authToken: AUTH_TOKEN,
  dsn: SENTRY_DSN || "https://0aa898422e97401289dc306d5e3a00dd@o1144939.ingest.sentry.io/6295365",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "development",
  integrations: [new BrowserTracing()],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
