import { flow, IMSTArray, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import {
  BrandModel,
  FilterModel,
  ProductCategoryModel,
  ServiceItemGroupModel,
  SpecialEquipmentCodeModel,
} from "../models"
import { IProduct, IFilterType, EProductFilterKeys, ISortOption } from "../types"

export type TSortDirections =
  | "number ASC"
  | "number DESC"
  | "navision_description ASC"
  | "navision_description DESC"
  | ""

export const ProductSearchStoreModel = types
  .model("ProductSearchStoreModel")
  .props({
    currentProductCategory: types.maybeNull(types.reference(ProductCategoryModel)),
    currentServiceItemGroup: types.maybeNull(types.reference(ServiceItemGroupModel)),
    currentSpecialEquipmentCode: types.maybeNull(types.reference(SpecialEquipmentCodeModel)),
    currentBrand: types.maybeNull(types.reference(BrandModel)),
    currentVendor: types.maybeNull(types.reference(BrandModel)),
    activeFilterOption: types.maybeNull(FilterModel),
    query: types.optional(types.string, ""),
    quickSearchQuery: types.optional(types.string, ""),
    quickAddSearchQuery: types.optional(types.string, ""),
    brand: types.array(types.string),
    item_class: types.array(types.string),
    product_group: types.array(types.string),
    products: types.array(types.frozen<IProduct>()),
    quickSearchProducts: types.array(types.frozen<IProduct>()),
    quickAddSearchProducts: types.array(types.frozen<IProduct>()),
    resultsCount: types.maybeNull(types.number),
    quickSearchResultsCount: types.maybeNull(types.number),
    quickAddSearchResultsCount: types.maybeNull(types.number),
    filters: types.array(types.frozen<IFilterType>()),
    sortOptions: types.array(types.frozen<ISortOption>()),
    sortBy: types.maybeNull(types.frozen<ISortOption>()),
    totalPages: types.maybeNull(types.number),
    page: types.optional(types.number, 1),
    isSearching: types.maybeNull(types.boolean),
    isLoadingCustomerBestPrices: types.maybeNull(types.boolean),
    isLoadingCurrentProductBestPrice: types.maybeNull(types.boolean),
    isQuickSearching: types.maybeNull(types.boolean),
    isQuickAddSearching: types.maybeNull(types.boolean),
    isRefreshing: types.optional(types.boolean, false),
    isLoadingFilters: types.optional(types.boolean, false),
    samplesOnly: types.maybeNull(types.boolean),
    currentProduct: types.maybeNull(types.frozen<IProduct>()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get sort() {
      return self.sortBy?.key || ""
    },
    get appliedFilters() {
      return [...self.item_class, ...self.product_group, ...self.brand]
    },
    get searchComplete() {
      return !R.isNil(self.resultsCount)
    },
    get quickSearchComplete() {
      return !R.isNil(self.quickSearchResultsCount)
    },
    get sortDisplayName() {
      return self.sortBy?.displayName
    },
  }))
  .actions((self) => ({
    reset() {
      self.currentProductCategory =
        self.currentServiceItemGroup =
        self.currentSpecialEquipmentCode =
        self.currentBrand =
        self.currentVendor =
        self.activeFilterOption =
        self.resultsCount =
        self.totalPages =
        self.samplesOnly =
        self.sortBy =
          null
      self.query = ""
      self.brand = self.item_class = self.product_group = [] as any
      self.page = 1
      self.products = self.quickSearchProducts = [] as any
    },
    quickAddReset() {
      self.currentProductCategory =
        self.currentServiceItemGroup =
        self.currentSpecialEquipmentCode =
        self.activeFilterOption =
        self.resultsCount =
        self.totalPages =
        self.samplesOnly =
        self.sortBy =
          null
      self.query = ""
      self.brand = self.item_class = self.product_group = [] as any
      self.page = 1
      self.products = self.quickAddSearchProducts = [] as any
    },

    resetQuickSearch() {
      self.quickSearchProducts = [] as any
      self.quickSearchQuery = ""
      self.quickSearchResultsCount = null
      self.isQuickSearching = false
    },
    resetQuickAddSearch() {
      self.quickAddSearchProducts = [] as any
      self.quickAddSearchQuery = ""
      self.quickAddSearchResultsCount = null
      self.isQuickAddSearching = false
    },
    toggleFilterOption(option: { [key: string]: any }, filterKey: EProductFilterKeys) {
      const isChecked = self[filterKey] && self[filterKey].length > 0 ? R.includes(option.key, self[filterKey]) : false

      if (isChecked) {
        self[filterKey].remove(option.key)
      } else {
        self[filterKey].push(option.key)
      }
    },
    setQuery(query: string) {
      self.query = query
    },
    setBrand(brand: string[]) {
      self.brand = brand || ([] as any)
    },
    setPage(page: number) {
      self.page = page || (1 as any)
    },
    setItemClass(itemClass: string[]) {
      self.item_class = itemClass || ([] as any)
    },
    setProductGroup(productGroup: string[]) {
      self.product_group = productGroup || ([] as any)
    },
    setQuickSearchQuery(query: string) {
      self.quickSearchQuery = query
    },
    setQuickAddSearchQuery(query: string) {
      self.quickAddSearchQuery = query
    },
    setCurrentSpecialEquipmentCode(sec) {
      self.rootStore.catalogueStore.setSpecialEquipmentCode(sec)
      self.currentSpecialEquipmentCode = sec.slug
    },
    setCurrentServiceItemGroup(sig) {
      self.rootStore.catalogueStore.setServiceItemGroup(sig)
      self.currentServiceItemGroup = sig.slug
    },
    setCurrentBrand(brand) {
      self.rootStore.brandStore.setBrand(brand)
      self.currentBrand = brand.slug
    },
    setCurrentVendor(brand) {
      self.rootStore.brandStore.setBrand(brand)
      self.currentVendor = brand.slug
    },
    setCurrentProductCategory(id) {
      const currentProductCategory = self.rootStore.catalogueStore.getCategory(id)
      self.currentProductCategory = currentProductCategory
      return currentProductCategory
    },
    setIsRefreshing(value: boolean) {
      self.isRefreshing = value
    },
    showFilterOptions(filter: { name: string; [key: string]: any }) {
      //TODO: VERIFY WE NEED THIS IN MOBILE WITH LAUREL
      //@ts-ignore
      self.activeFilterOption = filter
    },
    hideFilterOptions() {
      self.activeFilterOption = null
    },
    search: flow(function* () {
      return yield self.environment.api.getProducts(
        R.pathOr(null, ["currentProductCategory", "id"], self)!,
        R.pathOr(null, ["currentServiceItemGroup", "slug"], self)!,
        R.pathOr(null, ["currentSpecialEquipmentCode", "slug"], self)!,
        R.pathOr(null, ["currentBrand", "slug"], self)!,
        R.pathOr(null, ["currentVendor", "slug"], self)!,
        self.page,
        self.query,
        self.sort,
        self.brand,
        self.item_class,
        self.product_group,
        self.samplesOnly,
      )
    }),
    getAvailableFilters: flow(function* () {
      self.isLoadingFilters = true
      const response = yield self.environment.api.getAvailableFilters(
        R.pathOr(null, ["currentProductCategory", "id"], self)!,
        R.pathOr(null, ["currentServiceItemGroup", "slug"], self)!,
        R.pathOr(null, ["currentSpecialEquipmentCode", "slug"], self)!,
        R.pathOr(null, ["currentBrand", "slug"], self)!,
        R.pathOr(null, ["currentVendor", "slug"], self)!,
        self.query,
        self.brand,
        self.item_class,
        self.product_group,
        self.samplesOnly,
      )
      self.isLoadingFilters = false
      return response
    }),
    setSamplesOnly() {
      self.samplesOnly = true
    },
    quickSearch: flow(function* () {
      self.isQuickSearching = true
      self.page = 1
      const response = yield self.environment.api.quickSearch(self.quickSearchQuery)
      if (response.ok) {
        self.quickSearchProducts = response.data.products
        self.quickSearchResultsCount = response.data.total
      }
      self.totalPages = 1

      self.isQuickSearching = false
    }),
    quickAddSearch: flow(function* () {
      self.isQuickAddSearching = true
      self.page = 1
      const response = yield self.environment.api.quickSearch(self.quickAddSearchQuery)
      if (response.ok) {
        self.quickAddSearchProducts = response.data.products
        self.quickAddSearchResultsCount = response.data.total
      }
      self.totalPages = 1

      self.isQuickAddSearching = false
    }),
  }))
  .actions((self) => ({
    fetchProducts: flow(function* (page: number = 1) {
      self.isSearching = true
      self.page = page
      const response = yield self.search()
      if (response.ok && response.data) {
        self.products = response.data?.products || []
        self.sortOptions = response.data.sortOptions
        self.resultsCount = response.data.total
        self.totalPages = response.data.pages
      }
      self.isSearching = false
      const filtersResponse = yield self.getAvailableFilters()
      if (filtersResponse.ok) {
        self.filters = filtersResponse.data.availableFilters
      }
    }),
    getCustomerBestPrices: flow(function* (products: IProduct[]) {
      if (!self.rootStore.userStore.currentUser) return
      self.isLoadingCustomerBestPrices = true
      const erpCustomerNumber = self.rootStore.userStore.currentUser.erpCustomer?.number
      if (erpCustomerNumber) {
        const productNumbers = products.filter((p) => !p.comingSoon && !p.blocked).map((p) => p.anipetItemNumber)
        const response = yield self.environment.api.getCustomerBestPrices(erpCustomerNumber, productNumbers)
        if (response.ok) {
          const indexedBestPrices = R.indexBy(R.prop("number"), response.data.customerBestPrices)
          const mapped = R.map((item) => R.mergeRight(item, indexedBestPrices[item.number]), self.products)
          self.products = mapped
        }
      }
      self.isLoadingCustomerBestPrices = false
    }),
    getQuickAddBestPrice: flow(function* (product: IProduct) {
      if (!self.rootStore.userStore.currentUser) return
      self.isLoadingCustomerBestPrices = true
      const erpCustomerNumber = self.rootStore.userStore.currentUser.erpCustomer?.number
      if (erpCustomerNumber) {
        const response = yield self.environment.api.getCustomerBestPrices(erpCustomerNumber, [product.anipetItemNumber])
        if (response.ok) {
          const indexedBestPrices = R.indexBy(R.prop("number"), response.data.customerBestPrices)
          self.isLoadingCustomerBestPrices = false
          return R.mergeRight(product, indexedBestPrices[product.anipetItemNumber])
        }
      }
      self.isLoadingCustomerBestPrices = false
    }),

    getCurrentProductBestPrice: flow(function* (product: IProduct) {
      if (!self.rootStore.userStore.currentUser) return
      self.isLoadingCurrentProductBestPrice = true
      const erpCustomerNumber = self.rootStore.userStore.currentUser.erpCustomer?.number
      if (erpCustomerNumber) {
        const productNumber = product.anipetItemNumber
        const response = yield self.environment.api.getCustomerBestPrices(erpCustomerNumber, [productNumber])
        if (response.ok) {
          const indexedBestPrices = R.indexBy(R.prop("number"), response.data.customerBestPrices)
          const merged = R.mergeRight(self.currentProduct, indexedBestPrices[productNumber] || {})
          self.currentProduct = merged
        }
      }
      self.isLoadingCurrentProductBestPrice = false
    }),

    getNextPage: flow(function* () {
      //use this if we need infinite scrolling
      if (self.totalPages == self.page) return
      self.isSearching = true
      self.page++
      const response = yield self.search()
      if (response.ok && response?.data?.products) {
        self.products = self.products.concat(response.data.products) as IMSTArray<any>
      }
      self.isSearching = false
    }),
    getTargetPage: flow(function* (page: number) {
      self.isSearching = true
      self.page = page
      const response = yield self.search()
      //the web app does not need infinite scroll so we use this function instead
      if (response.ok && response?.data?.products) {
        self.products = response.data.products as IMSTArray<any>
      }
      self.isSearching = false
    }),
    fetchProductById: flow(function* (id) {
      self.isSearching = true
      const response = yield self.environment.api.getProductById(id)
      if (response.ok) {
        self.currentProduct = response.data
      }
      self.isSearching = false
    }),
    setSortBy(sortOption) {
      self.sortBy = sortOption
    },
  }))
  .actions((self) => ({
    resetFilters(router) {
      self.filters.forEach((filter) => {
        if (self[filter.filterKey]) {
          self[filter.filterKey] = [] as any
        }
      })
      const keepKeys = ["q", "id"]
      Object.keys(router.query).forEach((key) => {
        if (!keepKeys.includes(key)) delete router.query[key]
      })
      router.push(router)
      self.fetchProducts()
    },
    resetFilter(router, filterKey) {
      if (filterKey == EProductFilterKeys.attributes) {
        self.item_class = [] as any
        self.product_group = [] as any
        delete router.query.item_class
        delete router.query.product_group
      } else {
        self[filterKey] = [] as any
        delete router.query[filterKey]
      }
      router.push(router)
      self.fetchProducts()
    },
  }))

export interface IProductSearchStore extends Instance<typeof ProductSearchStoreModel> {}

