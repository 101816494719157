import { flow, Instance, types } from "mobx-state-tree"
import * as R from "ramda"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { IClient } from "../types"

export interface ISuggestion {
  label: string
  id?: number
}

export const ClientSearchStoreModel = types
  .model("ClientSearchStoreModel")
  .props({
    results: types.array(types.frozen<IClient>()),
    isSearching: types.maybeNull(types.boolean),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    isSearchable(query: string) {
      return query.length >= 2
    },
    get suggestions(): ISuggestion[] {
      const { erpCustomer } = self.rootStore.userStore.currentUser
      const clients: IClient[] = erpCustomer
        ? R.reject((result: IClient) => result.id == erpCustomer.id, self.results)
        : self.results
      const suggestions = R.map(
        (client: IClient) => ({
          label: `${client.number}-${client.name}-${client.state}`,
          id: client.id,
        }),
        clients,
      )
      return erpCustomer
        ? R.prepend(
            // @ts-ignore
            { label: translate("clientSelect.noClientOption") },
            suggestions,
          )
        : suggestions
    },
  }))
  .actions((self) => ({
    fetchSuggestions: flow(function* (query: string) {
      self.isSearching = true
      if (!self.isSearchable(query)) return
      const response = yield self.environment.api.fetchClients(query)
      if (response && response.ok) {
        self.results = response.data.erpCustomers
      }
      self.isSearching = false
    }),
  }))

export interface IClientSearchStore extends Instance<typeof ClientSearchStoreModel> {}
