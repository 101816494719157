import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const CartInProgressItemModel = types
  .model("CartInProgressItemModel")
  .props({
    productNumber: types.identifier, //unique product number
    quantity: types.number,
  })
  .extend(withRootStore())
  .extend(withEnvironment())

export interface ICartInProgressItem extends Instance<typeof CartInProgressItemModel> {}
