import { IMSTArray, Instance, types, flow } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { IAnipetRep } from "../types"

export const ProductActionsStoreModel = types
  .model("ProductActionsStoreModel")
  .props({
    productId: types.maybeNull(types.number),
    productActionElementIds: types.array(types.number),
    quantity: types.optional(types.string, "1"),
    shareWithRep: types.maybeNull(types.frozen<IAnipetRep>()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get actionsModalIsVisible() {
      return !R.isNil(self.productId)
    },
    get quantityAsInt() {
      return parseInt(self.quantity) || 0
    },
  }))
  .actions((self) => ({
    reset() {
      self.productId = null
      self.productActionElementIds.clear()
      self.quantity = "1"
    },
  }))
  .actions((self) => ({
    hideToggle() {
      self.reset()
    },
    showToggle(productId: number, quantity?: number) {
      self.productId = productId
      if (quantity) {
        self.quantity = quantity.toString()
      }
    },
    setProductActionElementIds(ids: number[]) {
      // @TODO: HACK.
      /* the IDs in this array are used to determine whether a "click" event happened within the product actions "modal" (and should propagate to the click handler) or outside of it (and should close the modal and not propagate).
      There is a weird issue that occurs when the product actions toggle component is rendered by two different parents (e.g. the cart screen and product list). The first time it is rendered, the ref that calls this method is set once as expected (see product-actions-toggle.tsx). When it's rendered as a child of the other parent, the ref is set twice with two different values. Both of these values are different from the original ref on the original screen. It is not clear which ref is correct so if we simply replace the element IDs here, the new IDs are not guaranteed to be correct. If the IDs are incorrect, the "click" event target ID will NOT exist in this list. The "click" will be considered as an 'outside' click and the "modal" will close.
      To fix this, instead of replacing the element IDs, we concat the current ids and the new ids. This ensures that when a button inside the product actions is clicked, the click event does not cause the actions "modal" to close. */
      self.productActionElementIds = R.union(self.productActionElementIds, ids) as IMSTArray<any>
    },
    resetProductActionElementIds() {
      self.productActionElementIds.clear()
    },
    setQuantity(quantity: string) {
      self.quantity = quantity
    },
    setShareWithRep: flow(function* (erpCustomerId?: number, product?: string) {
      const response = yield self.environment.api.shareWithRep(erpCustomerId, product)
      if (response && response.ok) {
        self.shareWithRep = response.data
      }
      return response
    }),
  }))

export interface IProductActionsStore extends Instance<typeof ProductActionsStoreModel> {}
