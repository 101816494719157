import { Instance, types } from "mobx-state-tree"
import { ICartItem } from "."
import { withEnvironment, withRootStore } from "../lib"
import { IProduct } from "../types"
import * as R from "ramda"

export const DealProductModel = types
  .model("DealProductModel")
  .props({
    id: types.identifierNumber,
    quantity: types.maybeNull(types.number),
    price: types.maybeNull(types.string),
    product: types.frozen<IProduct>(),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get existingCartItem(): ICartItem | undefined {
      return self.rootStore.cartStore.findProductInCart(self.product.anipetItemNumber)
    },
  }))
  .views((self) => ({
    get quantityFromCart() {
      return self.existingCartItem?.quantity
    },

    get quantityForDeal() {
      return R.isNil(self.quantity) ? self.existingCartItem?.quantity || 0 : self.quantity
    },
  }))
  .actions((self) => ({
    setQuantity(quantity: number) {
      self.quantity = quantity
    },
  }))

export interface IDealProduct extends Instance<typeof DealProductModel> {}
