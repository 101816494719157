import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IBarcodeImage, IProduct } from "../types"

export const BarcodeStoreModel = types
  .model("BarcodeStoreModel")
  .props({
    hasPermission: types.optional(types.boolean, false),
    scanInProgress: types.maybeNull(types.boolean),
    scannedProduct: types.maybeNull(types.frozen<IProduct>()),
    scannedBarcode: types.maybeNull(types.string),
    scannedBarcodeImage: types.maybeNull(types.frozen<IBarcodeImage>()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    setHasPermission(status: boolean) {
      self.hasPermission = status
    },
    setScanInProgress() {
      self.scanInProgress = true
    },
    resetScanInProgress() {
      self.scanInProgress = false
    },
    setScannedBarcode(barcode: string) {
      self.scannedBarcode = barcode
    },
    resetScannedBarcode() {
      self.scannedBarcode = null
    },
    setScannedBarcodeImage(image: IBarcodeImage) {
      self.scannedBarcodeImage = image
    },
    fetchScannedProduct: flow(function* () {
      const response = yield self.environment.api.getProductByBarcode(self.scannedBarcode!)
      if (response.ok) {
        self.scannedProduct = response.data
      } else {
        //toast message get ssent automatically
      }
      return response
    }),
  }))

export interface IBarcodeStore extends Instance<typeof BarcodeStoreModel> {}
