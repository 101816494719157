import { resolve } from "node:path/win32"
import { mst } from "reactotron-mst"

import { rootStore } from "./index"

declare global {
  interface Console {
    tron: any
  }
}

export const reactotronSetup = async (api) => {
  if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    const reactotronImport = await import("reactotron-react-js")
    const apiSauceImport = await import("reactotron-apisauce")
    const reactotron = reactotronImport.default
    const tronsauce = apiSauceImport.default

    type TReactotron = typeof reactotron

    reactotron
      .configure({ name: "anipet_web" })
      .use(tronsauce())
      .use(
        mst({
          filter: (x) => !x.name.endsWith("@APPLY_SNAPSHOT"),
        }),
      )
      .connect()

    // @ts-ignore
    reactotron?.trackMstNode?.(rootStore)
    // @ts-ignore
    api.addMonitor(reactotron.apisauce)

    console.tron = reactotron as TReactotron
    return "success"
  } else {
    return "failed"
  }
}
